import { OptimiseQuoteComment } from "../pages/Brief/Detail/utils/quotation.type";
import { LayoutCount } from "../reducers/briefElementConfigurator/types";
import {
  CadStatus,
  LayoutTypeName,
  PackingTypeName,
  PalletTypeName
} from "../reducers/briefs/constant";
import { BriefStatus } from "../reducers/briefStatuses/entity";
import { CorrugatedComposition } from "../reducers/corrugated-composition/entity";
import { Flute } from "../reducers/flutes/entity";
import { Liner } from "../reducers/liners/entity";
import { Product } from "../reducers/products/entity";
import { LayoutNode } from "../reducers/products/type";
import { ExchangeRate } from "../services/currencies/currencies";
import { CustomFieldValue } from "../services/customFields/customFieldValues/types";
import { InkDigitalMode } from "../services/inksDigitalMode/type";
import { InkDigitalType } from "../services/inksDigitalType/type";
import { PalletFormat } from "../services/palletFormat/type";
import { DynamicJsonTranslator } from "../utils/function/jsonTranslator";
import { BasicVarnish } from "./basicVarnish";
import { BriefComputed } from "./briefComputed";
import { CloudlabPersonalization } from "./cloudlabPersonalization";
import { Address, Company } from "./company";
import {
  PrintMethodName,
  SideVariationPrintName,
  StampingMethodName,
  VarnishMethodName
} from "./decoration";
import { EmbossingDebossing } from "./embossingDebossing";
import { HotStamping } from "./hotstamping";
import { InkParameter } from "./inkParameter";
import { Intercom } from "./intercom";
import { Lamination } from "./lamination";
import { LuxuryVarnish } from "./luxuryVarnish";
import { LuxuryVarnishParameter } from "./luxuryVarnishParameter";
import { Machine } from "./machine";
import { MaterialReference } from "./materialReference";
import { MaterialType } from "./materialType";
import { Matter } from "./matter";
import { PredictionRequest } from "./predictionRequest";
import { ProductCategory } from "./productCategory";
import { Project } from "./project";
import { QuantityCount } from "./quantityCount";
import { User } from "./user";
import { VarnishParameter } from "./varnishParameter";
import { Window } from "./window";

export enum SideName {
  INSIDE = "Inside",
  OUTSIDE = "Outside",
  INSIDE_AND_OUTSIDE = "Inside_and_Outside"
}

export enum ConfiguratorContext {
  CREATION = "Creation",
  DUPLICATION = "Duplication",
  OPTIMISATION = "Optimisation"
}

export type CreatedBrief = Omit<Brief, "id">;

export type BriefWithPrediction = Brief &
  Pick<
    BriefWithRelations,
    "predictionRequest" | "briefComputed" | "briefElements"
  >;

export interface BriefWithRelations extends Brief {
  briefElements: Array<BriefElementWithRelations>;
  user?: User;
  predictionRequest: PredictionRequest;
  briefComputed?: BriefComputed;
  processComments?: Record<string, Record<string, OptimiseQuoteComment[]>>;
  valueAnalysisComments?: Record<string, OptimiseQuoteComment[]>;
}

export class BriefElementPrice {
  id!: string;
  unitPrice!: number;
  quantity!: number;
  createdAt!: Date;
  updatedAt!: Date;
  briefElementId!: string;
  briefElement?: BriefElement;
}

export interface BriefElementWithRelations extends BriefElement {
  price: BriefElementPrice;
  window?: Window;
  product: Product;
  productCategory: ProductCategory;
  matter?: Matter;
  materialType?: MaterialType;
  materialReference?: MaterialReference;
  lamination?: Lamination;
  LaminationRecto?: Lamination;
  LaminationVerso?: Lamination;
  basicVarnish?: BasicVarnish;
  luxuryVarnish?: LuxuryVarnish;
  polyesterLamination?: Lamination;
  polyesterLaminationRecto?: Lamination;
  polyesterLaminationVerso?: Lamination;
  materialFlute?: Flute;
  materialLinerRecto?: Liner;
  materialLinerVerso?: Liner;
  corrugatedMaterialCompositions?: CorrugatedComposition[];
  flexoVarnishRecto?: VarnishParameter;
  flexoVarnishVerso?: VarnishParameter;
  cmykParamsVerso?: InkParameter[];
  directToneParamsVerso?: InkParameter[];
  cmykParamsRecto?: InkParameter[];
  directToneParamsRecto?: InkParameter[];
  cmykParamsOffsetVerso?: InkParameter[];
  directToneParamsOffsetVerso?: InkParameter[];
  cmykParamsOffsetRecto?: InkParameter[];
  directToneParamsOffsetRecto?: InkParameter[];
  directToneParamsScreenPrintingVerso?: InkParameter[];
  directToneParamsScreenPrintingRecto?: InkParameter[];
  cmykwParamsDigitalRecto?: InkParameter[];
  cmykwParamsDigitalVerso?: InkParameter[];
  dieCutMachines?: Machine[];
  digitalPrintingModeVerso?: InkDigitalMode;
  digitalPrintingModeRecto?: InkDigitalMode;
  digitalPrintingTypeVerso?: InkDigitalType;
  digitalPrintingTypeRecto?: InkDigitalType;
  varnishParamsOffsetRecto?: VarnishParameter[];
  varnishParamsOffsetVerso?: VarnishParameter[];
  luxuryVarnishParamsRecto?: LuxuryVarnishParameter[];
  luxuryVarnishParamsVerso?: LuxuryVarnishParameter[];
  lithoLaminationMaterialReferenceRecto?: MaterialReference;
  lithoLaminationMaterialReferenceVerso?: MaterialReference;
  cloudlabPersonalizationId?: string;
  cloudlabPersonalization?: CloudlabPersonalization;
}

export enum BriefAfterProcessingStatus {
  UNDEFINED = 0,
  REQUESTED = 1,
  IN_PROGRESS = 2,
  ABORTED = 3,
  PROCESSED = 4,
  CRASH = 5
}

export class BriefElement {
  id!: string;
  briefId?: string;
  position!: number;
  productCategoryId!: string;
  productId!: string;
  lengthDimension?: number;
  widthDimension?: number;
  heightDimension?: number;
  widthGluedFlap?: number;
  widthInsideFlap?: number;
  specifyRequest?: string;
  totalRuleLength?: number;
  distanceBetweenLayoutHeight?: number;
  distanceBetweenLayoutWidth?: number;
  bagPieces?: number;
  addWindow?: boolean;
  windowWidth?: number;
  windowHeight?: number;
  windowWantProtection?: boolean;
  windowId?: string;
  specifyWindow?: string;
  matterId!: string;
  materialTypeId!: string;
  grammage!: number;
  materialReferenceId!: string;
  materialReferenceName!: string;
  cardboardVersoPosition!: boolean;
  wantDecoration?: boolean;
  referencesCount!: number;
  sidePrinted?: SideName;
  versoColorTypeCMYK?: boolean;
  versoDirectToneColorType?: boolean;
  versoDirectToneColorCount?: number;
  versoColorCount?: number;
  versoColorQuality?: string;
  versoInkPercentage?: number;
  rectoColorTypeCMYK?: boolean;
  rectoDirectToneColorType?: boolean;
  rectoDirectToneColorCount?: number;
  rectoColorCount?: number;
  rectoColorQuality?: string;
  rectoInkPercentage?: number;
  additionalInkScreenPrinting?: boolean;
  additionalInkScreenPrintingColorCount?: number;
  additionalInkScreenPrintingPercentage?: number;
  needStamping?: boolean;
  stampingMethod?: string;
  polyesterLaminationId?: string;
  hotstampings?: HotStamping[];
  wantProtection?: boolean;
  protectionIds?: string[];
  wantLuxury?: boolean;
  wantFinishes?: boolean;
  sideFinished?: SideName;
  wantOffsetPrinting?: boolean;
  laminationId?: string;
  basicVarnishId?: string;
  luxuryVarnishId?: string;
  wantEmbossingDebossing?: boolean;
  embossingDebossingParamsRecto?: EmbossingDebossing[];
  embossingDebossingParamsVerso?: EmbossingDebossing[];
  decorationsCount!: QuantityCount[];
  price?: BriefElementPrice;
  addSealingStrip?: boolean;
  addTearStrip?: boolean;
  addMecanisation?: boolean;
  sideVariationPrint?: SideVariationPrintName;
  wantDecorAmalgam?: boolean;
  printMethodVerso?: PrintMethodName;
  wantPrintOnEdgeVerso?: boolean;
  wantFullSurfaceVerso?: boolean;
  wantPrintOnCreasingVerso?: boolean;
  wantTechnicalPrintingVerso?: boolean;
  printMethodRecto?: PrintMethodName;
  wantPrintOnEdgeRecto?: boolean;
  wantFullSurfaceRecto?: boolean;
  wantPrintOnCreasingRecto?: boolean;
  wantTechnicalPrintingRecto?: boolean;
  materialFluteId?: string;
  materialQuality?: number;
  materialLinerRectoId?: string;
  materialLinerVersoId?: string;
  corrugatedMaterialId?: string;
  corrugatedMaterialCompositionIds?: string[];
  sideProtected?: SideName;
  flexoVarnishRectoId?: string;
  finishPartialSurfaceRecto?: boolean;
  flexoVarnishCoverageRecto?: number;
  flexoVarnishVersoId?: string;
  finishPartialSurfaceVerso?: boolean;
  flexoVarnishCoverageVerso?: number;
  dividersLengthBigElement?: number;
  dividersLengthSmallElement?: number;
  dividersHeightElement?: number;
  dividersNotchBorderDistance?: number;
  dividersSpaceBetweenNotchesBigElement?: number;
  dividersSpaceBetweenNotchesSmallElement?: number;
  customLayout?: boolean;
  knifeToKnifeX?: number;
  knifeToKnifeY?: number;
  layoutTotalRuleLength?: number;
  layoutType?: LayoutTypeName;
  blanksTotal?: number;
  dieCutToolExist?: boolean;
  dieCutToolLayoutCount?: LayoutCount[];
  dieCutToolForce?: boolean;
  dieCutToolMachineIds?: string[];
  flexoPlateInkExistVerso!: boolean;
  flexoPlateInkForceVerso?: boolean;
  flexoPlateInkLayoutCountVerso?: LayoutCount[];
  flexoPlateInkExistRecto!: boolean;
  flexoPlateInkForceRecto?: boolean;
  flexoPlateInkLayoutCountRecto?: LayoutCount[];
  flexoPlateVarnishExistVerso!: boolean;
  flexoPlateVarnishForceVerso?: boolean;
  flexoPlateVarnishLayoutCountVerso?: LayoutCount[];
  flexoPlateVarnishExistRecto!: boolean;
  flexoPlateVarnishForceRecto?: boolean;
  flexoPlateVarnishLayoutCountRecto?: LayoutCount[];
  product?: Product;
  external?: Record<string, number>;
  inputLayout?: LayoutNode[];
  subCategories?: DynamicJsonTranslator[];
  subCategoriesIds?: string[];
  digitalPrintingModeRectoId?: string;
  digitalPrintingModeVersoId?: string;
  digitalPrintingTypeRectoId?: string;
  digitalPrintingTypeVersoId?: string;
  lithoLaminationMaterialReferenceRectoId?: string;
  lithoLaminationMaterialReferenceVersoId?: string;
  lithoLaminationExtraBorderRecto?: number;
  lithoLaminationExtraBorderVerso?: number;
  stampingMethodRecto?: StampingMethodName;
  stampingMethodVerso?: StampingMethodName;
  varnishMethodRecto?: VarnishMethodName;
  varnishMethodVerso?: VarnishMethodName;
  corrugatedMaterialCompositions?: CorrugatedComposition[];
}

export class Brief {
  id!: string;
  code!: string;
  name?: string;
  deliveryDecision?: boolean;
  intercom?: Intercom;
  deliveryAddressId?: string;
  deliveryAddress?: Partial<Address>;
  deliveryZipCode?: string;
  hasPrice?: boolean;
  _errorMessage?: string;
  comment?: string;
  statusId?: string;
  status?: BriefStatus;
  userId!: string;
  briefComputedId!: string;
  createdAt!: Date;
  updatedAt!: Date;
  projectId?: string | null;
  project?: Project | null;
  company?: Company;
  user?: User;
  briefElements?: BriefElement[];
  predictionRequest?: PredictionRequest;
  briefComputed?: BriefComputed;
  sampleStatus?: CadStatus | null;
  dielineStatus?: CadStatus | null;
  multiUpStatus?: CadStatus | null;
  context?: ConfiguratorContext;
  parentId?: string | null;
  parent?: Brief | null;
  rootId?: string | null;
  root?: Brief | null;
  hasTimeout?: boolean;
  customFields?: CustomFieldValue[];
  packingType?: PackingTypeName;
  specificBundleRequirement?: boolean;
  minQuantityBundle?: number;
  maxQuantityBundle?: number;
  minHeightBundle?: number;
  maxHeightBundle?: number;
  minWeightBundle?: number;
  maxWeightBundle?: number;
  palletType?: PalletTypeName;
  palletFormatId?: string;
  palletFormat?: PalletFormat;
  palletCustomWidth?: number;
  palletCustomLength?: number;
  palletCustomHeight?: number;
  palletCustomWeight?: number;
  palletStackable?: boolean;
  palletOverhang?: boolean;
  maxPalletOverhang?: number;
  maxPalletHeight?: number;
  maxPalletUnderload?: number;
  afterProcessingStatus?: BriefAfterProcessingStatus;
  partsCount?: number;
  exchangeRate?: ExchangeRate;
  sellSettingsId?: string;
}

export interface BriefCreatedResponse extends BriefWithRelations {}
