import { boolean, MixedSchema, number, string } from "yup";
import { BriefWithRelations } from "../../../../entities/brief";
import { PredictionRequestComputed } from "../../../../entities/predictionRequestComputed";
import { DecorationCount } from "../../../../entities/decorationsCount";
import { DynamicJsonTranslator } from "../../../../utils/function/jsonTranslator";
import { User } from "../../../../entities/user";
import { SideNamePrediction } from "../../../../entities/predictionOperation";
import {
  BriefPriceSequence,
  PredictionRequest
} from "../../../../entities/predictionRequest";
import { Event } from "../../../../services/events/type";
import { CostBreakdown } from "../../../../entities/costBreakdown";

export type DispatchContent = {
  type: BriefProviderActionType;
  payload?: any;
  signature?: string;
  edit?: OptimiseQuoteEditFields;
  comment?: OptimiseQuoteComment;
  valueAnalysis?: Omit<OptimiseQuoteEditFields, "index">;
  process?: string;
};

export enum SemiSupervisedSourceDB {
  MACHINE = "machine",
  PROVIDER = "provider",
  OPERATION = "operation"
}

export interface OptimiseLockedFields {
  meta:
    | Record<
        string,
        {
          operation?: string;
          machine?: string;
          layout?: string;
        }
      >
    | Record<
        string,
        Record<
          SideNamePrediction,
          {
            operation?: string;
            machine?: string;
            layout?: string;
          }
        >
      >;
}

export type OptimiseQuoteComment = {
  comment?: string;
  userId?: string; //not usefull as we already have the decorator (@CurrentUser)
  position: number;
  field: string;
  withPosition?: boolean;
  metaOperationId?: string; // always needed
  operationId?: string;
  user?: User;
  isValueAnalysis?: boolean;
  partNumber?: number;
};

export type OptimiseQuoteEditFields = {
  field: string;
  value: any;
  name?: DynamicJsonTranslator;
  prevName?: DynamicJsonTranslator;
  index: number;
  prevValue?: any;
  side?: SideNamePrediction;
  locked?: boolean;
  metaOperation?: string;
  label: string;
  source?: SemiSupervisedSourceDB;
  unit?: string;
  hasCurrency?: boolean;
  partNumber?: number;
  extra?: Record<string, any>;
  isExtraCost?: boolean;
  version?: number;
  isAdded?: boolean;
};

export interface AliciaPredictionScenario {
  optimized_product_quantity: number;
  optimized_product_quantity_per_decoration: DecorationCount[];
  production_time: number;
  signature: string;
  detail_signature: any;
}

export interface DetailSignature {
  input_sheet_height: number;
  input_sheet_layout_height: number;
  quantity: number;
  input_sheet_layout_pattern: string;
  input_sheet_layout_total: number;
  machine_direction: string;
  input_sheet_layout_width: number;
  input_sheet_max_tolerance_height: number;
  input_sheet_max_tolerance_width: number;
  input_sheet_width: number;
  internal: boolean;
  operation_id: string;
  machine_id: string;
  output_sheet_height: number;
  output_sheet_layout_height: number;
  output_sheet_layout_pattern: string;
  output_sheet_layout_total: number;
  output_sheet_layout_width: number;
  output_sheet_max_tolerance_height: number;
  output_sheet_max_tolerance_width: number;
  output_sheet_width: number;
  input_knife_to_knife_width: number;
  input_knife_to_knife_height: number;
  input_working_format_width: number;
  input_working_format_height: number;
  total_layout: number;
  total_rule_length: number;
  position: number;
  provider_id: string;
  sheet_material_name: string;
  sheet_range_max: number;
  sheet_range_min: number;
  sheet_stock: boolean;
  side: string;
  print_case: number;
  print_varnishs: number;
  side_position: number;
  print_colors: number;
  meta_operations: string[];
  input_sheet_forced_layout: boolean;
  output_sheet_forced_layout: boolean;
}

export type MetaWithSide = Record<
  "recto" | "verso",
  {
    operation: string[];
    machine: string[];
    layout: string[];
    provider: string[];
  }
>;

export interface CostBreakdownChange {
  productionDetail: boolean;
  suppliesDetail: boolean;
  toolingDetail: boolean;
  externalOperationDetail: boolean;
  deliveryDetail: boolean;
}

export interface AliciaAlternativePredictionScenario
  extends Omit<AliciaPredictionScenario, "sequence"> {
  detail_signature: DetailSignature[];
  // Base 64 encoded string of the sequence that's zipped
  sequence: string;
  total_cost: number;
  operationIds: string[];
  providerIds: string[];
  machineIds: string[];
  layouts: Record<string, string[]>;
  meta: Record<
    string,
    {
      operation: string[];
      machine: string[];
      layout: string[];
      provider: string[];
    }
  >;
}

export type BriefProviderDefault = {
  predictionRequestComputedDefault?: PredictionRequestComputed;
  valueAnalysisDefault?: PredictionRequest;
  additionalCostsDefault?: Partial<CostBreakdown>;
  costBreakdownDefault?: Partial<CostBreakdown>;
};

export type BriefProviderPartDefault = {
  predictionRequestComputedDefault?: PredictionRequestComputed;
  valueAnalysisDefault?: PredictionRequest;
  sequenceMatrixDefault?: AliciaAlternativePredictionScenario[];
  operationIdsDefault?: string[];
  machineIdsDefault?: string[];
  providerIdsDefault?: string[];
  layoutsDefault?: Record<string, string[]>;
};

export type OptimiseQuoteComputedProcessCount = {
  totalScenario?: number;
  prevTotalScenario?: number;
  newPrice?: number;
  oldPrice?: number;
  percentagePrice?: string;
  percentageScenario?: string;
};

export type OptimiseQuotePartProcess = {
  sequenceMatrix?: AliciaAlternativePredictionScenario[];
  providerIds?: string[];
  operationIds?: string[];
  machineIds?: string[];
  layouts?: Record<string, string[]>;
  signature?: string;
  signatures?: string[];
  default?: BriefProviderPartDefault;
  scenario?: AliciaAlternativePredictionScenario;
};

export type OptimiseQuoteComputedProcess = {
  predictionRequestComputed?: PredictionRequestComputed;
  operationSequence?: Record<string, OptimiseQuoteEditFields>;
  version?: number;
  default?: BriefProviderDefault;
  comments?: Record<string, OptimiseQuoteComment>;
  count?: OptimiseQuoteComputedProcessCount;
  prevEvent?: Record<string, OptimiseQuoteEditFields>;
  parts?: Record<string, string>;
  partNumber?: number;
  isMultiPart?: boolean;
  partsCount?: number;
  // string is the part number
  optimiseQuoteComputedPartProcess?: Record<string, OptimiseQuotePartProcess>;
  skeletons?: boolean;
};

export type BriefProviderState = {
  brief?: BriefWithRelations;
  skeletons?: boolean;
  predictionRequestComputed?: PredictionRequestComputed;
  valueAnalysis?: PredictionRequest;
  comments?: Record<string, OptimiseQuoteComment>;
  costBreakdown?: Partial<CostBreakdown>;
  operationSequence?: Record<string, OptimiseQuoteEditFields>;
  operationIds?: string[];
  providerIds?: string[];
  machineIds?: string[];
  layouts?: Record<string, string[]>;
  version?: number;
  default: BriefProviderDefault;
  process: number;
  processes: Record<string, OptimiseQuoteComputedProcess>;
  costBreakdownUpdates: Record<string, Partial<OptimiseQuoteEditFields>>;
  costBreakdownChanges: CostBreakdownChange;
  isUpdatedOnce?: boolean;
  prevEvent?: Record<string, OptimiseQuoteEditFields>;
  additionalCosts?: Partial<CostBreakdown>;
  removedAdditionalCosts?: Record<string, Partial<OptimiseQuoteEditFields>>;
  additionalCostsComment?: string;
};

export type OptimiseAction =
  | {
      type: BriefProviderActionType.SET_PREDICTION_REQUEST;
      payload: PredictionRequestComputed;
      priceSequences?: BriefPriceSequence[];
      version?: number;
      process?: number;
    }
  | {
      type: BriefProviderActionType.SET_PREDICTION_REQUEST_ON_CHANGE;
      payload: PredictionRequestComputed;
      isMultiPart?: boolean;
    }
  | {
      type: BriefProviderActionType.SET_VALUE_ANALYSIS;
      payload: PredictionRequest;
      valueAnalysisEvents?: Record<string, OptimiseQuoteEditFields>;
    }
  | {
      type: BriefProviderActionType.SET_VALUE_ANALYSIS_ON_CHANGE;
      payload: PredictionRequest;
    }
  | {
      type: BriefProviderActionType.SET_SEQUENCE_MATRIX;
      payload?: AliciaAlternativePredictionScenario[];
      partNumber?: number;
      default?: AliciaAlternativePredictionScenario[];
      process?: number;
    }
  | {
      type: BriefProviderActionType.SET_OPERATIONS_IDS;
      payload?: string[];
      partNumber?: number;
      default?: string[];
      process?: number;
    }
  | {
      type: BriefProviderActionType.SET_MACHINE_IDS;
      payload?: string[];
      partNumber?: number;
      default?: string[];
      process?: number;
    }
  | {
      type: BriefProviderActionType.SET_PROVIDER_IDS;
      payload?: string[];
      partNumber?: number;
      default?: string[];
      process?: number;
    }
  | {
      type: BriefProviderActionType.SET_LAYOUTS;
      payload?: Record<string, string[]>;
      partNumber?: number;
      default?: Record<string, string[]>;
      process?: number;
    }
  | {
      type: BriefProviderActionType.SET_SIGNATURE;
      signature: string;
      partNumber: number;
    }
  | { type: BriefProviderActionType.SET_PROCESS; process: number }
  | {
      type: BriefProviderActionType.SET_PROCESS_COUNT;
      count: OptimiseQuoteComputedProcessCount;
    }
  | {
      type: BriefProviderActionType.SET_SCENARIO;
      payload?: AliciaAlternativePredictionScenario;
      partNumber?: number;
      process?: number;
    }
  | { type: BriefProviderActionType.SET_SKELETONS }
  | {
      type: BriefProviderActionType.SET_COMMENT;
      comment: OptimiseQuoteComment;
      partNumber: number;
    }
  | {
      type: BriefProviderActionType.SET_PREV_EVENT;
      payload: Event[];
    }
  | {
      type: BriefProviderActionType.LOCK_FIELD;
      edit: OptimiseQuoteEditFields;
      partNumber: number;
    }
  | {
      type: BriefProviderActionType.LOCK_FIELD_ANALYSIS;
      valueAnalysis: OptimiseQuoteEditFields;
    }
  | {
      type: BriefProviderActionType.UPDATE_SEQUENCE_MATRIX;
      payload: AliciaAlternativePredictionScenario[];
      partNumber?: number;
    }
  | {
      type: BriefProviderActionType.UPDATE_PREDICTION_REQUEST;
      edit: OptimiseQuoteEditFields;
      partNumber: number;
    }
  | {
      type: BriefProviderActionType.UPDATE_VALUE_ANALYSIS;
      valueAnalysis: Omit<OptimiseQuoteEditFields, "index">;
    }
  | {
      type: BriefProviderActionType.ADD_ADDITIONAL_COSTS;
      additionalCosts: Omit<OptimiseQuoteEditFields, "index">;
      comment?: string;
    }
  | {
      type: BriefProviderActionType.REMOVE_ADDITIONAL_COSTS;
      key: string;
      isAdded?: boolean;
    }
  | {
      type: BriefProviderActionType.CLEAR_PROCESS_COUNT;
      partNumber?: number;
    }
  | {
      type: BriefProviderActionType.CLEAR_COMMENTS;
      partNumber?: number;
    }
  | {
      type: BriefProviderActionType.CLEAR_HIGHLIGHT;
      partNumber?: number;
    }
  | {
      type: BriefProviderActionType.CLEAR_OPERATION_SEQUENCE;
      partNumber?: number;
    }
  | {
      type: BriefProviderActionType.REMOVE_COMMENT;
      comment: OptimiseQuoteComment;
      partNumber: number;
    }
  | { type: BriefProviderActionType.RESET_FIELD }
  | { type: BriefProviderActionType.RESET; withPrice?: boolean }
  | { type: BriefProviderActionType.CLEAR; withPrice?: boolean };

export enum BriefProviderActionType {
  SET_PREDICTION_REQUEST = "setPredictionRequestComputed",
  SET_PREDICTION_REQUEST_ON_CHANGE = "setPredictionRequestComputedOnChange",
  SET_VALUE_ANALYSIS = "setValueAnalysis",
  SET_VALUE_ANALYSIS_ON_CHANGE = "setValueAnalysisOnChange",
  SET_SEQUENCE_MATRIX = "setSequenceMatrix",
  SET_OPERATIONS_IDS = "setOperationsIds",
  SET_MACHINE_IDS = "setMachineIds",
  SET_PROVIDER_IDS = "setProviderIds",
  SET_LAYOUTS = "setLayouts",
  SET_SIGNATURE = "setSignature",
  SET_PROCESS = "setProcess",
  SET_PROCESS_COUNT = "setProcessCount",
  SET_SCENARIO = "setScenario",
  SET_SKELETONS = "setSkeletons",
  SET_COMMENT = "setComment",
  SET_PREV_EVENT = "setPrevEvent",
  LOCK_FIELD = "lockField",
  LOCK_FIELD_ANALYSIS = "lockFieldAnalysis",
  UPDATE_SEQUENCE_MATRIX = "updateSequenceMatrix",
  UPDATE_PREDICTION_REQUEST = "updatePredictionRequestComputed",
  UPDATE_VALUE_ANALYSIS = "updateValueAnalysis",
  ADD_ADDITIONAL_COSTS = "addAdditionalCosts",
  REMOVE_ADDITIONAL_COSTS = "removeAdditionalCosts",
  CLEAR_PROCESS_COUNT = "clearProcessCount",
  CLEAR_COMMENTS = "clearComments",
  CLEAR_HIGHLIGHT = "clearHighlight",
  CLEAR_OPERATION_SEQUENCE = "clearOperationSequence",
  REMOVE_COMMENT = "removeComment",
  RESET_FIELD = "resetField",
  RESET = "reset",
  CLEAR = "clear"
}

export enum BriefNodeType {
  TEXT = "text",
  TEXT_AREA = "textArea",
  NUMBER = "number",
  PERCENTAGE = "percentage",
  SWITCH = "switch",
  SELECT = "select",
  SELECT_DB = "selectDb",
  TIMER = "timer"
}

export const setBriefDetailSchema = (type: BriefNodeType): any => {
  let neverHappens: never;
  /* const require = getKeyLogic<boolean>(LogicAction.REQUIRE, node, "require");
  const min = getKeyLogic<number>(LogicAction.MIN, node, "min");
  const max = getKeyLogic<number>(LogicAction.MAX, node, "max"); */
  switch (type) {
    case BriefNodeType.NUMBER:
    case BriefNodeType.PERCENTAGE:
      return number().required();
    case BriefNodeType.TEXT:
    case BriefNodeType.TEXT_AREA:
    case BriefNodeType.TIMER:
      return string().required();
    case BriefNodeType.SELECT:
      return string().required();
    case BriefNodeType.SWITCH:
      return boolean();
    case BriefNodeType.SELECT_DB:
      return string().required();
    default:
      neverHappens = type;
      return neverHappens;
  }
};
