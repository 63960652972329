import { DieCutToolCosts } from "../services/dieCutToolCosts/type";
import { DynamicJsonTranslator } from "../utils/function/jsonTranslator";
import { MachinesOperationsRate } from "./machinesOperationsRate";

export enum SheetWidthInMachineDirection {
  MACHINE_DIRECTION = "machine_direction",
  MACHINE_CROSS_DIRECTION = "machine_cross_direction",
  BOTH = "both"
}

export enum PrintingDirection {
  RECTO = "recto",
  VERSO = "verso",
  BOTH = "both",
  SINGLE_SIDE_BOTH = "single_side_both"
}

export type Machine = {
  id: string;
  code: number;
  name: string;
  label: DynamicJsonTranslator;
  enabled: boolean;
  constraintOpeningMin: number | null;
  constraintOpeningMax: number | null;
  constraintAdvanceMin: number | null;
  constraintAdvanceMax: number | null;
  extraMarginLeft: number | null;
  extraMarginFront: number | null;
  extraMarginRight: number | null;
  extraMarginBack: number | null;
  spaceBetweenLayoutOpening: number | null;
  spaceBetweenLayoutAdvance: number | null;
  layoutToleranceAdvanceFront: number | null;
  layoutToleranceAdvanceBack: number | null;
  layoutToleranceOpeningRight: number | null;
  layoutToleranceOpeningLeft: number | null;
  layoutToleranceAdvanceDistributed: number | null;
  layoutToleranceOpeningDistributed: number | null;
  layoutCountOpeningMin: number | null;
  layoutCountOpeningMax: number | null;
  layoutCountAdvanceMin: number | null;
  layoutCountAdvanceMax: number | null;
  sheetWidthInMachineDirection?: SheetWidthInMachineDirection | null;
  colorGroupCount: number | null;
  varnishGroupCount: number | null;
  mixedGroupCount: number | null;
  minRollingTime: number | null;
  maxRollingTime: number | null;
  rate?: MachinesOperationsRate[];
  handleCrease: boolean;
  handleMecanization: boolean;
  requiresCrease: boolean;
  requiresExtraFlat: boolean;
  handleTechnicalPrinting: boolean;
  handleEdgePrinting: boolean;
  handleCreasePrinting: boolean;
  handleOnlyRowColumnLayout: boolean;
  edgePrintingMarginTop: number | null;
  edgePrintingMarginRight: number | null;
  edgePrintingMarginBottom: number | null;
  edgePrintingMarginLeft: number | null;
  inline: boolean;
  runMax: number | null;
  allowedPrintingSide: PrintingDirection | null;
  diecutToolCosts: DieCutToolCosts[];
  maximumPalletisationHeight: number | null;
};
export interface MachineTranslated extends Omit<Machine, "label"> {
  label: string;
}

export enum MachineNames {
  CTP = "CTP",
  K529_5CLS_V = "K529 5CLS+V,",
  K540_5CLS_V = "K540 5CLS+V",
  K640_6CLS_UV_V = "K640 6CLS UV+V",
  DIGITAL_PRINTING = "DIGITAL PRINTING",
  SPS_VITESSA_SERIG = "SPS VITESSA SERIG",
  MACHINE_A_PARFUMER = "MACHINE A PARFUMER",
  SPERIA_106_DEC = "SPERIA 106 DEC",
  SPERIA_106_GAUF = "SPERIA 106 GAUF",
  MASSICOT = "MASSICOT",
  SBS_DORURE = "SBS - DORURE",
  PLIEUSE_COLLEUSE = "PLIEUSE COLLEUSE",
  PLIEUSE_COLLEUSE_REFOULEUSE = "PLIEUSE COLLEUSE REFOULEUSE",
  KHOMAN = "KHOMAN",
  EUROPROGETTI = "EUROPROGETTI",
  DIGISTAR = "DIGISTAR",
  PAQUETAGE_DECORTICAGE = "PAQUETAGE/DECORTICAGE",
  CELLOPHANAGE = "CELLOPHANAGE",
  ENSACHEUSE = "ENSACHEUSE",
  MISE_EN_POLYBAG = "MISE EN POLYBAG",
  POSE_DE_FENETRE = "POSE DE FENETRE",
  AGRAFEUSE = "AGRAFEUSE",
  MONTAGE_CARNETS = "MONTAGE CARNETS",
  DECOUPE_CARCASSE = "DECOUPE CARCASSE",
  DECORTICAGE_CARCASSE = "DECORTICAGE CARCASSE",
  COLORISTE = "COLORISTE",
  PAO = "PAO",
  COMBINE = "COMBINE",
  MAC = "MAC",
  AUTOPLATINE = "AUTOPLATINE",
  MULTI = "MULTI",
  GB4 = "GB4",
  LOGISTICS = "LOGISTICS",
  MONTEUSE_CASIER = "MONTEUSE CASIER"
}

export interface MachineCreateDTO {}

export interface MachineUpdateDTO {
  id: string;
  label: DynamicJsonTranslator;
  enabled: boolean;
}
