import { Formik, FormikConfig, yupToFormErrors } from "formik";
import React from "react";
import { Schema } from "yup";

export interface MyFormikProps<T> extends FormikConfig<T> {
  validationSchema: Schema<any>;
  validationContext?: Record<string, any>;
  component?: React.FC<any>; // React.ComponentType<FormikProps<Values>>; avoid type error sentry
}

export const MyFormik: React.FC<MyFormikProps<any>> = ({
  validationSchema,
  validationContext,
  ...props
}) => (
  <Formik
    validate={async (values) => {
      try {
        await validationSchema.validate(values, {
          context: validationContext,
          abortEarly: false
        });
      } catch (err) {
        return yupToFormErrors(err);
      }
    }}
    validateOnBlur={false}
    validateOnChange={true}
    {...props}
  />
);
