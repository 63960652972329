import { createSelector } from "reselect";
import { RootState } from "..";
import { AdminRoles, RoleStatus, StandardRoles } from "../../entities/role";
import { User } from "../../entities/user";
import { RolesWithContext } from "./../../entities/role";
import { EachUserRole } from "./types";

export const getAuthenticationState = createSelector(
  (state: RootState) => state.authentication,
  (authentication) => authentication
);

export const getCurrentUser = createSelector(
  (state: RootState) => state,
  (state) =>
    ({
      ...state.authentication.user,
      selectedCompanyId: state.contact.selectedCompanyId
    } as User & { selectedCompanyId: string | null })
);

export const isAuthenticated = createSelector(
  getAuthenticationState,
  (auth) => !!auth.user.token
);

export const getCurrentUserRoleNames = createSelector(getCurrentUser, (user) =>
  user.roles.map((r) => r.name)
);

export const getIsAdminUser = createSelector(getCurrentUserRoleNames, (roles) =>
  roles.some((r) => AdminRoles.includes(r))
);

export const getUserIsExternalSales = createSelector(
  getCurrentUserRoleNames,
  (roles) => roles.includes(StandardRoles.EXTERNAL_SALES)
);

export const getIsPackitooUser = createSelector(
  getCurrentUserRoleNames,
  (roles) => roles.includes(StandardRoles.PACKITOO)
);

export const getIsSuperAdminUser = createSelector(
  getCurrentUserRoleNames,
  (roles) => roles.includes(StandardRoles.SUPER_ADMIN)
);

export const getIsMoreThanAdmin = createSelector(
  getIsPackitooUser,
  getIsSuperAdminUser,
  (isPackitoo, isSuperAdmin) => isPackitoo || isSuperAdmin
);

export const getIsGuestUser = createSelector(
  getCurrentUser,
  (user) => user.roles.length === 0
);

export const getIsNotGuestUser = createSelector(
  getCurrentUser,
  (user) => user.roles.length !== 0
);

// TOBE REMOVED
export const findUserRoleStatus = (user: User): RoleStatus => {
  const roleNames = (user.roles || []).map((r) => r.name);
  if (roleNames.some((r) => AdminRoles.includes(r))) {
    return RoleStatus.IS_ADMIN;
  } else if (roleNames.includes(StandardRoles.EXTERNAL_SALES)) {
    return RoleStatus.IS_EXTERNAL_SALES;
  } else {
    return RoleStatus.IS_GUEST;
  }
};

export const findEachUserRoleStatus = (user: User) => {
  const roleNames = (user?.roles || []).map((r) => r?.name);
  const rolesStatus = Object.values(StandardRoles).reduce(
    (prev, curr) => ({ ...prev, [curr]: roleNames.includes(curr) }),
    {} as Record<StandardRoles, boolean>
  );
  const contextRoleStatus = {
    isAdmin: roleNames.some((r) => AdminRoles.includes(r)),
    [RolesWithContext.CONTACT]:
      roleNames.length === 0 &&
      user.roles.length === 0 &&
      user.collaborationIds.length > 0,
    [RolesWithContext.GUEST]:
      user.roles.length === 0 && user.collaborationIds.length === 0,
    [RolesWithContext.CMS_USER]: !!user.cmsToken
  };
  return { ...rolesStatus, ...contextRoleStatus };
};

export const getUserRoleStatus = createSelector(
  getCurrentUser,
  findUserRoleStatus
);

export const getEachUserRoleStatus = createSelector(
  getCurrentUser,
  findEachUserRoleStatus
);

export const getEachUserStandardRoleStatus = createSelector(
  getEachUserRoleStatus,
  (roleStatus): EachUserRole => {
    roleStatus[RolesWithContext.GUEST] =
      roleStatus[RolesWithContext.GUEST] ||
      roleStatus[RolesWithContext.CONTACT];
    return roleStatus;
  }
);

export const getIsErpExternalSalesAllowed = createSelector(
  (state: RootState) => state,
  (state) => state?.appSettings?.setting?.allowExternalSalesErp || false
);

/** Returns true if the app should display to the current user the ERP IDs when exist */
export const isErpAllowedByUser = createSelector(
  getAuthenticationState,
  getIsErpExternalSalesAllowed,
  getIsAdminUser,
  (auth, isExternal, isAdmin) =>
    auth.user.roles.length !== 0 && (isExternal || isAdmin)
);
