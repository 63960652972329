import { createSelector } from "reselect";
import { RootState } from "..";
import { getCurrentUser, getIsPackitooUser } from "../authentication/selector";
import { Company } from "../../entities/company";

export const getIsDebug = createSelector(
  (state: RootState) => state,
  (state) => state?.appSettings?.setting.features?.isDebug || false
);

export const getIsDebugPackitoo = createSelector(
  getIsDebug,
  getIsPackitooUser,
  (isDebug, isPackitoo) => isDebug && isPackitoo
);

export const getIsCofn = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.features?.isCollectionOfNeeds || false
);

export const getIsConfigurator = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.features?.isConfigurator || false
);

export const getProjectDocumentTab = createSelector(
  getIsCofn,
  getIsConfigurator,
  (isCofn, isConfigurator) =>
    isCofn && isConfigurator
      ? "3"
      : (isCofn && !isConfigurator) || (!isCofn && isConfigurator)
      ? "2"
      : "1"
);

export const getCofnTab = createSelector(
  getIsCofn,
  getIsConfigurator,
  (isCofn, isConfigurator) =>
    isCofn && !isConfigurator ? "1" : isConfigurator && isCofn ? "2" : undefined
);

export const getConfiguratorTab = createSelector(
  getIsCofn,
  getIsConfigurator,
  (isCofn, isConfigurator) => (isConfigurator ? "1" : undefined)
);

export const getIsCustomFields = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.features?.isCustomFields || false
);

export const getLocalisations = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.localisations || []
);

export const getAllEnabledLocalisations = createSelector(
  getLocalisations,
  (localisations) => localisations.filter((l) => l.enable)
);

export const getDefaultLocalisation = createSelector(
  getLocalisations,
  (localisations) => localisations.find((l) => l.default)
);

export const getCurrencies = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.currencies || []
);

export const getAllEnabledCurrencies = createSelector(
  getCurrencies,
  (currencies) => currencies.filter((c) => c.enable)
);

export const getDefaultExchangeRate = createSelector(
  (state: RootState) => state,
  (state) => state?.appSettings?.setting?.defaultCurrency?.defaultRate
);

export const getCurrencyExchangeRate = createSelector(
  (state: RootState) => state,
  (state) => state?.appSettings?.setting?.currencies.map((c) => c.defaultRate)
);

// For back office settings and case where there's no currency/session/user
export const getDefaultCurrency = createSelector(getCurrencies, (currencies) =>
  currencies.find((c) => c.default)
);

export const getSessionCurrency = createSelector(getCurrencies, (currencies) =>
  currencies.find((c) => sessionStorage?.getItem("currency") === c.code)
);

// userCurrency > defaultCurrency
export const getUserCurrency = createSelector(
  getCurrencies,
  getCurrentUser,
  (currencies, user) => currencies.find((c) => user?.currencyId === c.id)
);

// companyCurrency > defaultCurrency
export const getCompanyCurrency = (company?: Company) => {
  if (!company || !company.currencyId) {
    return getDefaultCurrency;
  }

  const companyCurrency = createSelector(getCurrencies, (currencies) => {
    return currencies.find((c) => company?.currencyId === c.id);
  });

  return companyCurrency || getDefaultCurrency;
};

// sessionCurrency > userCurrency > defaultCurrency
export const getConfigCurrency = createSelector(
  getSessionCurrency,
  getUserCurrency,
  getDefaultCurrency,
  (sessionCurrency, userCurrency, defaultCurrency) =>
    sessionCurrency || userCurrency || defaultCurrency
);
