import { Box, Select, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppSetCurrency } from "../../../../reducers/app/action";
import { getAllEnabledCurrencies } from "../../../../reducers/appSettings/selector";
import { useTranslation } from "react-i18next";
import { getCurrency, getLanguage } from "../../../../reducers/app/selector";

interface CurrenciesSelectorProps {
  onClose?: () => void;
  isResponsive?: boolean;
}

export const CurrenciesSelector: React.FC<CurrenciesSelectorProps> = ({
  onClose,
  isResponsive
}) => {
  const dispatch = useDispatch();
  const activeCurrency = useSelector(getCurrency);

  const allCurrencies = useSelector(getAllEnabledCurrencies);

  const { t: tCurr } = useTranslation("currency");
  const { t } = useTranslation("backoffice");

  const setCurrency = (currency: string) => {
    const selecterdCurrency = allCurrencies.find(
      (curr) => curr.code === currency
    );
    if (!selecterdCurrency) return;
    dispatch(new AppSetCurrency(selecterdCurrency));
    sessionStorage.setItem("currency", currency);
  };

  if (allCurrencies?.filter((curr) => curr.enable).length === 1) {
    return null;
  }

  return (
    <Box
      mr={2}
      display="flex"
      alignItems="center"
      style={{ ...(isResponsive && { padding: "0 8px" }) }}
    >
      <Select
        name="language"
        onChange={(e) => {
          setCurrency(e.target.value as string);
          onClose && onClose();
        }}
        value={activeCurrency?.code}
      >
        {allCurrencies.map((curr) => (
          <MenuItem key={curr.id} value={curr.code}>
            {/* {`${tCurr(`${curr.code}`)} (${curr.symbol})`} */}
            {`${curr.code} (${curr.symbol})`}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
