import { CostBreakdown } from "./../../../../entities/costBreakdown";
import { PredictionRequestComputed } from "../../../../entities/predictionRequestComputed";
import {
  BriefProviderState,
  BriefProviderActionType,
  AliciaAlternativePredictionScenario,
  OptimiseQuoteEditFields,
  OptimiseLockedFields,
  OptimiseAction,
  OptimiseQuoteComputedProcess,
  OptimiseQuotePartProcess
} from "./quotation.type";
import { conciliateObjects, deepMergeOrdered } from "./deepMerge";
import { PredictionRequest } from "../../../../entities/predictionRequest";
import { flattenObjectToPaths } from "../../../Cofn/helpers/formNodeKeyPath";
import { Event } from "../../../../services/events/type";
import { PredictionOperation } from "../../../../entities/predictionOperation";
import {
  costBreakDownChangeInit,
  costBreakdownMap,
  excludedRelationFields,
  optimiseQuoteComputedFields,
  optimiseQuoteSearchFields,
  OptimiseQuoteSearchFieldsIndex,
  optimiseQuoteTimeFields
} from "./quotation.constant";
import { PrintingDirection } from "../../../../entities/machine";
import { omit, unset } from "lodash";
import { convertToHours, formatSetupTime } from "../utils";

function mapCostBreakdownUpdate(field: string): string {
  return costBreakdownMap[field] || field;
}

function removePathAndCleanEmpty(obj: any, path: string): any {
  const keys = path.split(".");

  function removePath(obj: any, keys: string[]): boolean {
    if (!obj || typeof obj !== "object") return false;

    const key = keys[0];

    // If we've reached the last key, delete it
    if (keys.length === 1) {
      delete obj[key];
    } else {
      // Otherwise, recurse into the next level
      if (removePath(obj[key], keys.slice(1))) {
        delete obj[key];
      }
    }

    // If the object is empty after deletion, return true to signal it
    return Object.keys(obj).length === 0;
  }

  // Make a deep clone to ensure no mutation of the original object
  const clonedObj = JSON.parse(JSON.stringify(obj));

  removePath(clonedObj, keys);

  return clonedObj;
}

function updateProcessState(
  state: BriefProviderState,
  currProcess: number,
  updates: OptimiseQuoteComputedProcess
): BriefProviderState {
  return {
    ...state,
    processes: {
      ...state.processes,
      [currProcess]: {
        ...state.processes?.[currProcess],
        ...updates
      }
    }
  };
}

function updateProcessPartState(
  state: BriefProviderState,
  currProcess: number,
  updates: OptimiseQuotePartProcess,
  partNumber?: number
): BriefProviderState {
  return {
    ...state,
    processes: {
      ...state.processes,
      [currProcess]: {
        ...state.processes?.[currProcess],
        ...(partNumber !== undefined
          ? {
              optimiseQuoteComputedPartProcess: {
                ...state.processes?.[currProcess]
                  ?.optimiseQuoteComputedPartProcess,
                [partNumber]: {
                  ...state.processes?.[currProcess]
                    ?.optimiseQuoteComputedPartProcess?.[partNumber],
                  ...updates
                }
              }
            }
          : updates)
      }
    }
  };
}

interface MetaCategory {
  operation?: string;
  machine?: string;
  layout?: string;
}

interface Meta {
  [category: string]: MetaCategory;
}

interface Category {
  layout: string[];
  machine: string[];
  operation: string[] | (string | null)[];
  provider: string[];
}

type SideCategories = {
  recto?: Category;
  verso?: Category;
};

type Categories =
  | { [key: string]: Category }
  | { [key: string]: SideCategories };

function isFullMatch(meta: Meta, categories: Categories): boolean {
  const matches = (metaCategory: any, category: Category) => {
    const providerMatch = metaCategory.provider
      ? category.provider.includes(metaCategory.provider)
      : true;
    const operationMatch = metaCategory.operation
      ? category.operation.includes(metaCategory.operation)
      : true;
    const machineMatch = metaCategory.machine
      ? category.machine.includes(metaCategory.machine)
      : true;
    const layoutMatch = metaCategory.layout
      ? category.layout.includes(metaCategory.layout)
      : true;

    return operationMatch && machineMatch && layoutMatch && providerMatch;
  };

  return Object.entries(meta).every(([key, metaCategory]) => {
    const categoryEntry = categories[key];
    if (!categoryEntry) return false;

    if (
      Object.keys(metaCategory).some(
        (side) =>
          side === PrintingDirection.RECTO || side === PrintingDirection.VERSO
      )
    ) {
      return Object.entries(metaCategory).every(([side, subMeta]) => {
        const category = (categoryEntry as any)[side];

        return category ? matches(subMeta, category) : false;
      });
    }

    return matches(metaCategory, categoryEntry as Category);
  });
}

export function computingSignature(
  matrix: AliciaAlternativePredictionScenario[],
  record: Meta
): {
  signature: string;
  totalCost: number;
  scenario: AliciaAlternativePredictionScenario;
} {
  return matrix?.reduce(
    (sequence, scenario) => {
      let sequenceHasMatch = false;

      if (isFullMatch(record, scenario.meta)) {
        sequenceHasMatch = true;
      }

      if (sequenceHasMatch && sequence.totalCost > scenario.total_cost) {
        sequence.signature = scenario.signature;
        sequence.totalCost = scenario.total_cost;
        sequence.scenario = scenario;
      }
      return sequence;
    },
    {
      signature: "",
      totalCost: Infinity,
      scenario: {} as AliciaAlternativePredictionScenario
    }
  );
}

export function filteringMatrix(
  matrix: AliciaAlternativePredictionScenario[],
  fields: OptimiseLockedFields
): {
  matrix: AliciaAlternativePredictionScenario[];
  operationIds: Set<string>;
  machineIds: Set<string>;
  providerIds: Set<string>;
  layouts: Record<string, string[]>;
} {
  const meta = fields.meta as any;

  return matrix?.reduce(
    (sequence, scenario) => {
      if (isFullMatch(meta, scenario.meta)) {
        sequence?.matrix.push(scenario);

        // Have put compiler options target   "target": "es2015",
        sequence.machineIds = new Set([
          ...sequence.machineIds,
          ...scenario.machineIds.filter(Boolean)
        ]);

        sequence.operationIds = new Set([
          ...sequence.operationIds,
          ...scenario.operationIds.filter(Boolean)
        ]);

        sequence.providerIds = new Set([
          ...sequence.providerIds,
          ...scenario.providerIds.filter(Boolean)
        ]);

        for (const [key, values] of Object.entries(scenario.layouts)) {
          if (!sequence.layouts[key]) {
            sequence.layouts[key] = [...values];
          } else {
            sequence.layouts[key] = Array.from(
              new Set([...sequence.layouts[key], ...values])
            );
          }
        }
      }
      return sequence;
    },
    {
      matrix: [] as AliciaAlternativePredictionScenario[],
      operationIds: new Set([]) as Set<string>,
      machineIds: new Set([]) as Set<string>,
      providerIds: new Set([]) as Set<string>,
      layouts: {} as Record<string, string[]>
    }
  );
}

export function briefReducer(
  state: BriefProviderState,
  action: OptimiseAction
): BriefProviderState {
  let neverHappen: never;
  switch (action.type) {
    case BriefProviderActionType.SET_PREDICTION_REQUEST: {
      const processIndex = action.process ?? state.process;
      const process = state.processes[processIndex];
      let parts;
      if (process?.default) {
        process.default.predictionRequestComputedDefault = action.payload;
      }

      const partsCount = action?.payload?.partsCount ?? 0;
      const isMultiPart = partsCount > 1;

      if (action?.payload?.parts) {
        parts = action.payload.parts;
      }

      if (action.version) {
        state.version = action.version;
      }

      return updateProcessState(state, processIndex, {
        predictionRequestComputed: action.payload,
        operationSequence:
          action.payload?.optimiseQuoteActions?.[processIndex] || {},
        isMultiPart,
        partsCount: partsCount,
        ...(parts && { parts })
      });
    }
    case BriefProviderActionType.SET_PREDICTION_REQUEST_ON_CHANGE: {
      const process = state.processes[state.process];
      if (action.isMultiPart && process.partNumber !== undefined) {
        const oldPredictionOperationSequence = process
          ?.predictionRequestComputed
          ?.predictionOperationSequence as PredictionOperation[];

        const newPredictionOperationSequence =
          action.payload?.predictionOperationSequence;

        const oldPredictionOperationSequenceWithoutPart = oldPredictionOperationSequence?.filter(
          (operation) => operation.partNumber !== process.partNumber
        );

        return updateProcessState(state, state.process, {
          predictionRequestComputed: {
            ...process.predictionRequestComputed!,
            predictionOperationSequence: [
              ...oldPredictionOperationSequenceWithoutPart,
              ...(newPredictionOperationSequence.map((operation) => {
                return { ...operation, partNumber: process.partNumber };
              }) as PredictionOperation[])
            ]
          }
        });
      }

      return updateProcessState(state, state.process, {
        predictionRequestComputed: action.payload
      });
    }
    case BriefProviderActionType.SET_VALUE_ANALYSIS: {
      let costBreakdown;
      if (action.valueAnalysisEvents) {
        state.costBreakdownUpdates = action?.valueAnalysisEvents;
        const costBreakdownValues = Object.entries(
          action?.valueAnalysisEvents
        ).reduce((acc, [key, value]) => {
          // We're not pushing the extra costs to the cost breakdown
          if (value?.isExtraCost) {
            return acc;
          }
          acc[key] = value.value;
          return acc;
        }, {} as Record<string, number>);

        costBreakdown = conciliateObjects(
          state.costBreakdown || {},
          costBreakdownValues
        );

        state.costBreakdown = costBreakdown;
      }

      return {
        ...state,
        valueAnalysis: action.payload,
        // remove the read mode when the value analysis is set
        additionalCosts: { ...action?.payload?.additionalCosts },
        default: {
          ...state.default,
          valueAnalysisDefault: action.payload,
          additionalCostsDefault: action?.payload?.additionalCosts || {},
          costBreakdownDefault: costBreakdown || {}
        }
      };
    }
    case BriefProviderActionType.SET_VALUE_ANALYSIS_ON_CHANGE:
      return {
        ...state,
        valueAnalysis: action.payload
      };
    case BriefProviderActionType.SET_SEQUENCE_MATRIX: {
      const processIndex = action.process ?? state.process;
      const part = action.partNumber;
      const process = state.processes[processIndex];
      const defaultPart =
        process?.optimiseQuoteComputedPartProcess?.[part!]?.default;

      return updateProcessPartState(
        state,
        processIndex,
        {
          sequenceMatrix: action.payload,
          default: {
            ...defaultPart,
            sequenceMatrixDefault: action.default
          }
        },
        part
      );
    }
    case BriefProviderActionType.SET_OPERATIONS_IDS: {
      const processIndex = action.process ?? state.process;
      const part = action.partNumber;
      const process = state.processes[processIndex];

      const defaultPart =
        process?.optimiseQuoteComputedPartProcess?.[part!]?.default;

      return updateProcessPartState(
        state,
        processIndex,
        {
          operationIds: action.payload,
          default: {
            ...defaultPart,
            operationIdsDefault: action.default
          }
        },
        part
      );
    }

    case BriefProviderActionType.SET_MACHINE_IDS: {
      const processIndex = action.process ?? state.process;
      const part = action.partNumber;
      const process = state.processes[processIndex];

      const defaultPart =
        process?.optimiseQuoteComputedPartProcess?.[part!]?.default;

      return updateProcessPartState(
        state,
        processIndex,
        {
          machineIds: action.payload,
          default: {
            ...defaultPart,
            machineIdsDefault: action.default
          }
        },
        part
      );
    }
    case BriefProviderActionType.SET_PROVIDER_IDS: {
      const processIndex = action.process ?? state.process;
      const part = action.partNumber;
      const process = state.processes[processIndex];

      const defaultPart =
        process?.optimiseQuoteComputedPartProcess?.[part!]?.default;

      return updateProcessPartState(
        state,
        processIndex,
        {
          providerIds: action.payload,
          default: {
            ...defaultPart,
            providerIdsDefault: action.default
          }
        },
        part
      );
    }

    case BriefProviderActionType.SET_LAYOUTS: {
      const processIndex = action.process ?? state.process;
      const process = state.processes[processIndex];
      const part = action.partNumber;

      const defaultPart =
        process?.optimiseQuoteComputedPartProcess?.[part!]?.default;

      return updateProcessPartState(
        state,
        processIndex,
        {
          layouts: action.payload,
          default: {
            ...defaultPart,
            layoutsDefault: action.default
          }
        },
        part
      );
    }
    case BriefProviderActionType.SET_SIGNATURE: {
      const currProcess = state.process;
      const part = action.partNumber as number;

      const optimiseQuoteProcess =
        state.processes[currProcess]?.optimiseQuoteComputedPartProcess?.[part];

      if (optimiseQuoteProcess) {
        if (optimiseQuoteProcess.signatures) {
          optimiseQuoteProcess.signature! = action.signature;
        } else {
          optimiseQuoteProcess.signatures = [action.signature];
        }
      }

      return updateProcessPartState(
        state,
        state.process!,
        {
          signature: action.signature,
          signatures: optimiseQuoteProcess?.signatures
        },
        part
      );
    }
    case BriefProviderActionType.SET_PROCESS: {
      return {
        ...state,
        process: action.process
      };
    }
    case BriefProviderActionType.SET_PROCESS_COUNT: {
      return updateProcessState(state, state.process!, {
        count: {
          ...state?.processes?.[state.process!]?.count,
          ...action.count
        }
      });
    }
    case BriefProviderActionType.SET_SCENARIO: {
      const processIndex = action.process ?? state.process;

      const partNumber = action.partNumber;

      return updateProcessPartState(
        state,
        processIndex,
        {
          scenario: action.payload
        },
        partNumber
      );
    }

    case BriefProviderActionType.SET_SKELETONS: {
      return updateProcessState(
        {
          ...state,
          skeletons:
            (Object.values(state?.processes)?.length as number) > 1
              ? true
              : false
        },
        state.process!,
        {
          skeletons: true
        }
      );
    }

    case BriefProviderActionType.SET_COMMENT: {
      const currProcess = state.process;
      const keyAccess = action?.comment?.withPosition
        ? `${action?.comment?.field}_${action?.comment?.position}_${action?.partNumber}`
        : `${action?.comment?.field}`;

      if (!action?.comment?.withPosition) {
        return {
          ...state,
          isUpdatedOnce: true,
          comments: {
            ...state.comments,
            [keyAccess]: {
              comment: action?.comment?.comment as string,
              position: action?.comment?.position as number,
              field: action?.comment?.field as string,
              isValueAnalysis: true
            }
          }
        };
      }

      return updateProcessState(state, currProcess, {
        comments: {
          ...state?.processes?.[currProcess]?.comments,
          [keyAccess]: {
            comment: action?.comment?.comment as string,
            position: action?.comment?.position as number,
            field: action?.comment?.field as string,
            metaOperationId: action?.comment?.metaOperationId as string,
            operationId: action?.comment?.operationId as string,
            partNumber: action?.partNumber
          }
        }
      });
    }
    case BriefProviderActionType.SET_PREV_EVENT: {
      const prevEvents = action.payload.reduce(
        (acc: any, event: Event) => {
          const process = event?.process;
          const payload = event?.payload as any;

          if (!process) {
            // Case extra cost comments events
            if (!payload) {
              return acc;
            }
            if (payload?.field) {
              acc.valueAnalysis[payload.field] = payload;
            }
          } else {
            const keyAccess = `${payload?.field}_${payload?.index}_${payload?.partNumber}`;
            acc.processes[process] = {
              ...acc.processes[process],
              [keyAccess]: payload
            };
          }
          return acc;
        },
        {
          valueAnalysis: {} as Record<string, OptimiseQuoteEditFields>,
          processes: {} as Record<string, OptimiseQuoteEditFields>
        }
      );

      if (prevEvents?.valueAnalysis) {
        state.prevEvent = prevEvents.valueAnalysis;
      }
      Object.entries(prevEvents?.processes).forEach(([process, events]) => {
        const currProcess = state?.processes?.[process];
        if (!currProcess) return;
        state.processes[process] = {
          ...currProcess,
          prevEvent: events as Record<string, OptimiseQuoteEditFields>
        };
      });

      return state;
    }

    case BriefProviderActionType.LOCK_FIELD: {
      let newValue;
      const currProcess = state.process;
      const sequences = state.processes?.[currProcess]
        ?.predictionRequestComputed?.predictionOperationSequence
        ? [
            ...state.processes?.[currProcess]?.predictionRequestComputed
              ?.predictionOperationSequence
          ]
            ?.filter((operation) =>
              typeof state.processes?.[currProcess]?.partNumber === "number"
                ? operation.partNumber ===
                  (state.processes?.[currProcess]?.partNumber as number)
                : operation
            )
            .sort((a, b) => a.position - b.position)
        : [];
      const keyAccess = `${action?.edit?.field}_${action?.edit?.index}_${action?.partNumber}`;
      const process = state.processes[currProcess];

      const prevName = process?.operationSequence?.[keyAccess]?.prevName;
      const prevValue = process?.operationSequence?.[keyAccess]?.prevValue;
      newValue = action?.edit?.value?.[`${action?.edit?.field}`];

      // Apply the conversion to hours if the field is a time field
      if (optimiseQuoteTimeFields.includes(action?.edit?.field as string)) {
        newValue = convertToHours({ time: formatSetupTime(newValue) })?.time;
      }

      // Update the operation sequence
      state.processes[currProcess].operationSequence = {
        ...(state.processes[currProcess]?.operationSequence || {}),
        [keyAccess]: {
          ...(state.operationSequence?.[keyAccess] || {}),
          field: action?.edit?.field as string,
          index: action?.edit?.index as number,
          value: newValue,
          locked: action?.edit?.locked,
          side: action?.edit?.side,
          metaOperation: action?.edit?.metaOperation,
          prevValue:
            prevValue ?? action?.edit?.prevValue?.[`${action?.edit?.field}`],
          prevName: prevName ?? action?.edit?.prevName,
          label: action?.edit?.label,
          unit: action?.edit?.unit,
          partNumber: action?.partNumber,
          extra: action?.edit?.extra,
          version: state?.version
        } as OptimiseQuoteEditFields
      };

      // Clear the field if the value is the same as the previous value
      if ((prevValue === newValue || !prevValue) && !action?.edit?.locked) {
        unset(state.processes[currProcess].operationSequence, keyAccess);
      }

      // Update the prediction request computed
      const operationSequence = Object.values(
        process?.operationSequence as Record<string, OptimiseQuoteEditFields>
      ).filter((operation) => operation.locked);

      // set the locked fields comparators with the metaOperation and side structure
      const lockedFields = operationSequence.reduce(
        (acc, sequence) => {
          const { field, value, metaOperation, side } = sequence;

          const withRelations = optimiseQuoteSearchFields.includes(
            field as OptimiseQuoteSearchFieldsIndex
          );

          if (withRelations && metaOperation) {
            const targetKeyMap: Record<string, string> = {
              machineId: "machine",
              operationId: "operation",
              layoutCount: "layout",
              providerId: "provider"
            };

            const targetKey = targetKeyMap[field];
            if (targetKey) {
              // Ensure the metaOperation and side structure exists
              if (!acc.meta[metaOperation]) {
                acc.meta[metaOperation] = {};
              }
              if (side) {
                if (!acc.meta[metaOperation][side]) {
                  acc.meta[metaOperation][side] = {};
                }

                // Assign the value to the appropriate key
                acc.meta[metaOperation][side][targetKey] = value;
              } else {
                // Assign the value to the appropriate key
                acc.meta[metaOperation][targetKey] = value;
              }
            }
          }

          return acc;
        },
        { meta: {} } as any
      );

      // Get the matrix based on the locked fields if it's locked
      const matrix = action?.edit?.locked
        ? process?.optimiseQuoteComputedPartProcess?.[
            action.partNumber as number
          ]?.sequenceMatrix
        : process?.optimiseQuoteComputedPartProcess?.[
            action.partNumber as number
          ]?.default?.sequenceMatrixDefault;

      // filter the matrix with the locked fields
      const updatedState = filteringMatrix(matrix!, lockedFields);

      updateProcessState(state, currProcess, {
        predictionRequestComputed: {
          ...process?.predictionRequestComputed,
          predictionOperationSequence: sequences?.map((operation, index) => {
            if (index === action?.edit?.index) {
              return {
                ...operation,
                ...action?.edit?.value
              };
            }
            return operation;
          })
        } as PredictionRequestComputed
      });

      // get the part sequence based on the partnumber (on single part it's always 0)
      const partSequence =
        process?.optimiseQuoteComputedPartProcess?.[
          action.partNumber as number
        ];

      return updateProcessPartState(
        { ...state, isUpdatedOnce: true },
        currProcess,
        {
          // Clear back to the default sequence matrix if there's no locked fields
          sequenceMatrix:
            operationSequence.length === 0
              ? partSequence?.default?.sequenceMatrixDefault
              : updatedState?.matrix,
          // Clear back to the default sequence matrix if there's no locked fields
          operationIds:
            operationSequence.length === 0
              ? partSequence?.default?.operationIdsDefault
              : Array.from(updatedState?.operationIds),
          // Clear back to the default sequence matrix if there's no locked fields
          machineIds:
            operationSequence.length === 0
              ? partSequence?.default?.machineIdsDefault
              : Array.from(updatedState?.machineIds),
          // Clear back to the default sequence matrix if there's no locked fields
          providerIds:
            operationSequence.length === 0
              ? partSequence?.default?.providerIdsDefault
              : Array.from(updatedState?.providerIds),
          // Clear back to the default sequence matrix if there's no locked fields
          layouts:
            operationSequence.length === 0
              ? partSequence?.default?.layoutsDefault
              : updatedState.layouts
        },
        action.partNumber
      );
    }

    case BriefProviderActionType.LOCK_FIELD_ANALYSIS: {
      const key = action?.valueAnalysis?.field;
      const prevValue = state?.costBreakdownUpdates?.[key]?.prevValue;
      const flattenObject = flattenObjectToPaths(action?.valueAnalysis?.value);
      const flattenKey = Object.keys(flattenObject)[0];
      const flattenValue = Object.values(flattenObject)[0];

      const isClear =
        (prevValue === flattenValue || !prevValue || !flattenValue) &&
        !action?.valueAnalysis?.locked;

      if (isClear) {
        return {
          ...state,
          costBreakdownUpdates: {
            ...omit(state.costBreakdownUpdates, flattenKey)
          }
        };
      }

      return {
        ...state,
        costBreakdownUpdates: {
          ...state.costBreakdownUpdates,
          [flattenKey]: {
            ...state.costBreakdownUpdates[flattenKey],
            locked: action.valueAnalysis.locked,
            value: flattenValue,
            prevValue: prevValue ?? flattenValue,
            label: action?.valueAnalysis?.label,
            source: action?.valueAnalysis?.source,
            unit: action?.valueAnalysis?.unit,
            hasCurrency: action?.valueAnalysis?.hasCurrency,
            version: state?.version
          }
        }
      };
    }
    case BriefProviderActionType.UPDATE_SEQUENCE_MATRIX:
      return updateProcessPartState(
        state,
        state.process!,
        {
          sequenceMatrix: action.payload
        },
        action.partNumber
      );

    case BriefProviderActionType.UPDATE_PREDICTION_REQUEST: {
      const currProcess = state.process;

      const process = state.processes[currProcess];

      const sequences = state.processes?.[currProcess]
        ?.predictionRequestComputed?.predictionOperationSequence
        ? [
            ...state.processes?.[currProcess]?.predictionRequestComputed
              ?.predictionOperationSequence
          ].sort((a, b) => a.position - b.position)
        : [];

      const keyAccess = `${action?.edit?.field}_${action?.edit?.index}_${action?.partNumber}`;
      const prevName = process?.operationSequence?.[keyAccess]?.prevName;
      const prevValue = process?.operationSequence?.[keyAccess]?.prevValue;
      const currValue = action?.edit?.value?.[`${action?.edit?.field}`];

      const isLocked = process?.operationSequence?.[keyAccess]?.locked;
      const isSearchFields = optimiseQuoteSearchFields.includes(
        action?.edit?.field as OptimiseQuoteSearchFieldsIndex
      );
      const shouldBeClean =
        prevValue === currValue && !isLocked && !isSearchFields;

      if (shouldBeClean) {
        delete state.processes[currProcess]?.operationSequence?.[keyAccess];
      }

      return updateProcessState(
        { ...state, isUpdatedOnce: true },
        currProcess,
        {
          predictionRequestComputed: {
            ...process?.predictionRequestComputed,
            predictionOperationSequence: sequences?.map((operation, index) => {
              if (
                (index === action?.edit?.index && !process?.isMultiPart) ||
                (process?.isMultiPart &&
                  action?.partNumber === operation.partNumber &&
                  operation?.position === action?.edit?.index)
              ) {
                return {
                  ...operation,
                  ...action?.edit?.value
                };
              }
              return operation;
            })
          } as PredictionRequestComputed,
          operationSequence: {
            ...process?.operationSequence,
            ...(!excludedRelationFields.includes(
              action?.edit?.field as string
            ) &&
              !shouldBeClean && {
                [keyAccess]: {
                  field: action?.edit?.field as string,
                  index: action?.edit?.index as number,
                  value: action?.edit?.value?.[`${action?.edit?.field}`],
                  name: action?.edit?.name,
                  side: action?.edit?.side,
                  // Dont override the prevValue/prevName if it's already set
                  prevValue:
                    prevValue ??
                    action?.edit?.prevValue?.[`${action?.edit?.field}`],
                  prevName: prevName ?? action?.edit?.prevName,
                  label: action?.edit?.label,
                  unit: action?.edit?.unit,
                  partNumber: action?.partNumber,
                  metaOperation: action?.edit?.metaOperation,
                  extra: action?.edit?.extra,
                  version: state?.version,
                  locked: true
                }
              })
          },
          ...(process?.isMultiPart && {
            partNumber: action?.partNumber
          })
        }
      );
    }

    case BriefProviderActionType.UPDATE_VALUE_ANALYSIS: {
      const flattenObject = flattenObjectToPaths(action?.valueAnalysis?.value);
      const flattenKey = Object.keys(flattenObject)[0];
      const flattenValue = Object.values(flattenObject)[0];
      const prevValue =
        state?.costBreakdownUpdates?.[flattenKey]?.prevValue ??
        Object.values(action?.valueAnalysis?.prevValue)[0];

      const costBreakDownFieldUpdate = mapCostBreakdownUpdate(
        action?.valueAnalysis?.field
      );

      const isLocked = state.costBreakdownUpdates?.[flattenKey]?.locked;

      const shouldBeClean = prevValue === flattenValue && !isLocked;

      if (shouldBeClean) {
        delete state.costBreakdownUpdates?.[flattenKey];
      }

      return {
        ...state,
        isUpdatedOnce: true,
        valueAnalysis: {
          ...state.valueAnalysis,
          costBreakdown: deepMergeOrdered(
            state?.valueAnalysis?.costBreakdown,
            action?.valueAnalysis?.value
          )
        } as PredictionRequest,
        costBreakdown: conciliateObjects(
          state.costBreakdown || {},
          action?.valueAnalysis?.value
        ),
        costBreakdownUpdates: {
          ...state.costBreakdownUpdates,
          ...(!shouldBeClean && {
            [flattenKey]: {
              ...state.costBreakdownUpdates[flattenKey],
              field: flattenKey,
              value: flattenValue,
              prevValue: prevValue ?? flattenValue,
              label: action?.valueAnalysis?.label,
              source: action?.valueAnalysis?.source,
              unit: action?.valueAnalysis?.unit,
              hasCurrency: action?.valueAnalysis?.hasCurrency,
              version: state?.version,
              locked: true
            }
          })
        },
        costBreakdownChanges: {
          ...state.costBreakdownChanges,
          [costBreakDownFieldUpdate]: true
        }
      };
    }

    case BriefProviderActionType.ADD_ADDITIONAL_COSTS: {
      const flattenObject = flattenObjectToPaths(
        action?.additionalCosts?.value
      );
      const flattenKey = Object.keys(flattenObject)[0];
      const flattenValue = Object.values(flattenObject)[0];
      const prevValue =
        state?.costBreakdownUpdates?.[flattenKey]?.prevValue ??
        Object.values(action?.additionalCosts?.prevValue)[0];

      const label =
        state?.costBreakdownUpdates?.[flattenKey]?.label ??
        action?.additionalCosts?.label;

      const isLocked = state.costBreakdownUpdates?.[flattenKey]?.locked;

      const shouldBeClean = prevValue === flattenValue && !isLocked;

      const costBreakDownFieldUpdate = mapCostBreakdownUpdate(
        action?.additionalCosts?.field
      );

      state.additionalCosts = conciliateObjects(
        state.additionalCosts || {},
        action?.additionalCosts?.value
      );

      return {
        ...state,
        isUpdatedOnce: true,
        valueAnalysis: {
          ...state.valueAnalysis,
          costBreakdown: deepMergeOrdered(
            state?.valueAnalysis?.costBreakdown,
            action?.additionalCosts?.value
          )
        } as PredictionRequest,
        costBreakdownUpdates: {
          ...state.costBreakdownUpdates,
          ...(!shouldBeClean && {
            [flattenKey]: {
              ...state.costBreakdownUpdates[flattenKey],
              field: flattenKey,
              value: flattenValue,
              prevValue: prevValue ?? flattenValue,
              label,
              source: action?.additionalCosts?.source,
              unit: action?.additionalCosts?.unit,
              hasCurrency: action?.additionalCosts?.hasCurrency,
              isExtraCost: true,
              version: state?.version,
              isAdded: action?.additionalCosts?.isAdded,
              locked: true
            }
          })
        },
        additionalCostsComment: action.comment,
        costBreakdownChanges: {
          ...state.costBreakdownChanges,
          [costBreakDownFieldUpdate]: true
        }
      };
    }

    case BriefProviderActionType.REMOVE_ADDITIONAL_COSTS: {
      const flattenKey = action?.key;
      const flattenKeyPath = flattenKey.split(".");

      const newAdditionalCosts = removePathAndCleanEmpty(
        state.additionalCosts,
        flattenKey
      );

      const deletedCostBreakDownUpdate =
        state?.costBreakdownUpdates[flattenKey];

      const costBreakdown = state?.valueAnalysis
        ?.costBreakdown as CostBreakdown;

      const updatedValueAnalysisCostBreakDown = removePathAndCleanEmpty(
        costBreakdown,
        `${flattenKeyPath[0]}.${flattenKeyPath[1]}`
      );

      if (Object.keys(newAdditionalCosts).length === 0) {
        state.additionalCostsComment = undefined;
      }

      return {
        ...state,
        valueAnalysis: {
          ...state.valueAnalysis,
          costBreakdown: updatedValueAnalysisCostBreakDown
        } as PredictionRequest,
        additionalCosts: newAdditionalCosts,
        costBreakdownUpdates: omit(state.costBreakdownUpdates, flattenKey),
        ...(!action.isAdded && {
          removedAdditionalCosts: {
            ...state.removedAdditionalCosts,
            [flattenKey]: deletedCostBreakDownUpdate
          }
        })
      };
    }

    case BriefProviderActionType.CLEAR_PROCESS_COUNT: {
      return updateProcessState(state, state.process!, {
        count: {}
      });
    }

    case BriefProviderActionType.CLEAR_COMMENTS: {
      {
        const process = state.processes[state.process!];
        const comments = process?.comments;
        const selectedPart = action.partNumber;

        if (comments && typeof selectedPart === "number") {
          const newComments = Object.fromEntries(
            Object.entries(comments).filter(
              ([_, comment]) => comment?.partNumber !== selectedPart
            )
          );

          return updateProcessState(
            { ...state, comments: {} },
            state.process!,
            {
              comments: newComments
            }
          );
        }

        return updateProcessState(
          {
            ...state,
            comments: {}
          },
          state.process!,
          {
            comments: {}
          }
        );
      }
    }

    case BriefProviderActionType.CLEAR_HIGHLIGHT: {
      const process = state.processes[state.process!];
      const prevEvents = process?.prevEvent;
      const selectedPart = action.partNumber;

      if (prevEvents && typeof selectedPart === "number") {
        const newPrevEvents = Object.fromEntries(
          Object.entries(prevEvents).filter(
            ([_, prevEvent]) => prevEvent?.partNumber !== selectedPart
          )
        );

        return updateProcessState({ ...state, prevEvent: {} }, state.process!, {
          prevEvent: newPrevEvents
        });
      }

      return updateProcessState(
        {
          ...state,
          prevEvent: {}
        },
        state.process!,
        {
          prevEvent: {}
        }
      );
    }

    case BriefProviderActionType.CLEAR_OPERATION_SEQUENCE: {
      const currentProcess = state.process as number;
      const process = state.processes[currentProcess];
      const operationSequence = process?.operationSequence;
      const partNumber = action.partNumber;

      // Clear operation sequence of the current process
      state.costBreakdown = {};
      state.costBreakdownUpdates = {};
      state.additionalCosts = {};
      state.additionalCostsComment = undefined;
      state.costBreakdownChanges = costBreakDownChangeInit;

      // Clear operation sequence of the current process
      if (operationSequence) {
        const operationSequences = Object.entries(operationSequence).reduce(
          (acc, [key, sequence]) => {
            if (
              sequence.partNumber === partNumber &&
              optimiseQuoteComputedFields.includes(sequence.field)
            ) {
              return acc;
            }
            acc[key] = sequence;
            return acc;
          },
          {} as Record<string, OptimiseQuoteEditFields>
        );

        return updateProcessState(state, currentProcess, {
          operationSequence: operationSequences
        });
      }

      return state;
    }

    case BriefProviderActionType.REMOVE_COMMENT: {
      const currProcess = state.process;
      const keyAccess = action?.comment?.withPosition
        ? `${action?.comment?.field}_${action?.comment?.position}_${action?.partNumber}`
        : `${action?.comment?.field}`;

      if (!action?.comment?.withPosition) {
        const comments = { ...state.comments };
        delete comments[keyAccess];

        return {
          ...state,
          comments
        };
      }

      const comments = { ...state.processes[currProcess].comments };
      delete comments[keyAccess];

      return updateProcessState(state, currProcess, {
        comments
      });
    }

    case BriefProviderActionType.RESET_FIELD: {
      // Do the opposite of the UPDATE_PREDICTION_REQUEST
      return state;
    }
    case BriefProviderActionType.CLEAR:
    case BriefProviderActionType.RESET: {
      const withPrice = action.withPrice;

      const processes = Object.entries(state.processes).reduce(
        (newProcess, [key, process]) => {
          {
            return {
              ...newProcess,
              [key]: {
                ...process,
                predictionRequestComputed:
                  process?.default?.predictionRequestComputedDefault,
                operationSequence: {},
                skeletons: false,
                partNumber: undefined,

                count: {}
                /* ...(!withPrice && { comments: {} }), */
              } as OptimiseQuoteComputedProcess
            };
          }
        },
        {} as Record<string, OptimiseQuoteComputedProcess>
      );
      return {
        ...state,
        /*         ...(!withPrice && { comments: {} }), */
        processes,
        skeletons: false,
        costBreakdown: { ...state.default.costBreakdownDefault },
        additionalCosts: { ...state.default.additionalCostsDefault },
        valueAnalysis: state.default.valueAnalysisDefault,
        isUpdatedOnce: false,
        costBreakdownChanges: costBreakDownChangeInit
      };
    }

    default:
      neverHappen = action;
      return neverHappen;
  }
}
