import { ProductCategory } from "../../entities/productCategory";
import { Role } from "../../entities/role";
import { User } from "../../entities/user";
import { CadPattern } from "../../services/cadConfiguration/type";
import { ProductSubCategory } from "../../services/subcategory/types";
import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";
import { CadSource } from "../cadSources/entity";
import { ConfiguratorInputOption } from "../configurator-input-options/entity";
import { File } from "../files/entity";
import { MachineRate } from "../machineRate/entity";
import { Operation } from "../operations/entity";
import { ProviderRate } from "../provider-rate/entity";
import { BriefElementConfiguratorValues } from "./../briefElementConfigurator/reducer";
import { LayoutNode } from "./type";

export enum ProductName {
  TuckTopAutoBottomFoldingBox = "TTAB_FB",
  ReverseTuckEndFoldingBox = "RTE_FB",
  StraightTuckEndFoldingBox = "STE_FB",
  TuckTopSnapLockBottomFoldingBox = "TTSLB_FB",
  OtherFoldingBox = "OFB",
  OtherRigidBox = "ORB",
  PerfumeStripes = "SSB",
  Sleeve = "SLEEVE",
  SelfLockTray = "SLT",
  OneSideFoldingBox = "OS_FB",
  OneSideTuckTopAutoBottomFoldingBox = "OS_TTAB_FB",
  SimpleCardWithoutFold = "SCWF",
  HeaderCardBagTopper = "HCBT",
  ComplexShapeBlotter = "CSB",
  Fefco201 = "FEFCO_201",
  Fefco201P = "FEFCO_201_P",
  Fefco204 = "FEFCO_204",
  Fefco204P = "FEFCO_204_P",
  Fefco206 = "FEFCO_206",
  Fefco210 = "FEFCO_210",
  Fefco211 = "FEFCO_211",
  Fefco215 = "FEFCO_215",
  Fefco217 = "FEFCO_217",
  Fefco330 = "FEFCO_330",
  Fefco330P = "FEFCO_330_P",
  Fefco401 = "FEFCO_401",
  Fefco402 = "FEFCO_402",
  Fefco406 = "FEFCO_406",
  Fefco421 = "FEFCO_421",
  Fefco422 = "FEFCO_422",
  Fefco422P = "FEFCO_422_P",
  Fefco423 = "FEFCO_423",
  Fefco423P = "FEFCO_423_P",
  Fefco427 = "FEFCO_427",
  Fefco503 = "FEFCO_503",
  Fefco503Bis = "FEFCO_503_BIS",
  Fefco713 = "FEFCO_713",
  Fefco901 = "FEFCO_901",
  Fefco902 = "FEFCO_902",
  Fefco9336 = "FEFCO_933_6",
  Fefco93312 = "FEFCO_933_12",
  Fefco934 = "FEFCO_934",
  Fefco951 = "FEFCO_951"
}

export interface ProductImage {
  productId: string;
  file: File;
  fileId: string;
  default: boolean;
}
export interface Product {
  id: string;
  name: string;
  label: DynamicJsonTranslator;
  externalReference: string | null;
  enabled: boolean;
  flatPackSurfaceHeightFormula?: string;
  flatPackSurfaceWidthFormula?: string;
  totalRuleLength?: string;
  modelResizing: boolean;
  modelWidth?: number | null;
  modelLength?: number | null;
  modelHeight?: number | null;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  productCategoryId: string;
  cadSourceId: string | null;
  operationIds: string[];
  images: ProductImage[];
  cadSource?: CadSource;
  createdById: string;
  productCategory?: ProductCategory;
  createdBy?: User;
  operations?: Operation[];
  inputLayout?: LayoutNode[];
  configuratorInputOptions?: ConfiguratorInputOption[];
  productLayoutPatterns?: CadPattern[];
  subCategoriesIds?: string[];
  subCategories?: ProductSubCategory[];
  roles?: Role[];
  roleIds?: string[];
  isAllowingGuest?: boolean;
  isQuantityOpen?: boolean;
  isDeliveryOpen?: boolean;
  isAdditionalOpen?: boolean;
  impact_cad_name: string | null;
  impactCadId: string | null;
  cloudlabProductId: string | null;
  cloudlabProduct: any | null;
  knockDownLength?: string;
  knockDownWidth?: string;
  knockDownHeightOne?: string;
  knockDownHeightTwo?: string;
  templateId?: string;
  deletedAt?: Date;
}

export type ProductCreateDTO = Pick<
  Product,
  "label" | "enabled" | "name" | "createdById"
>;
export type ProductArchivedDTO = Pick<Product, "id">;
export type ProductDuplicateDTO = Pick<Product, "label" | "name">;
export type ProductVisibilityDTO = Pick<
  Product,
  "roleIds" | "isAllowingGuest" | "id"
>;
export type ProductUpdateDTO = Partial<Product>;
export type ProductUpdateOperationsDTO = Pick<Product, "operationIds" | "id">;

export type ProductMachineRate = {
  setAsDefault: boolean;
  machineOperationRateId: string;
  machineRate: Omit<MachineRate, "id">;
};

export type ProductProviderRate = {
  setAsDefault: boolean;
  providerOperationRateId: string;
  providerRate: Omit<ProviderRate, "id">;
};

export type Scale3D = {
  x: number;
  y: number;
  z: number;
};

export const InitialScale3D: Scale3D = {
  x: 1,
  y: 1,
  z: 1
};

const computeDimensionScale = (reference?: number | null, value?: number) => {
  if (!reference || !value) return 1;
  return value / reference;
};

export const computeProductScale3D = (
  product: Product,
  values: BriefElementConfiguratorValues
): Scale3D => {
  return {
    x: computeDimensionScale(product.modelLength, values.lengthDimension),
    z: computeDimensionScale(product.modelWidth, values.widthDimension),
    y: computeDimensionScale(product.modelHeight, values.heightDimension)
  };
};
