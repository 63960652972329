import { BriefWithRelations } from "../../../../entities/brief";
import {
  BriefProviderState,
  OptimiseQuoteComputedProcess
} from "./quotation.type";

const defaultState = {
  valueAnalysisDefault: undefined
};

const defaultProcessState = {
  predictionRequestComputedDefault: undefined
};

export const initialQuotationStateProcess: OptimiseQuoteComputedProcess = {
  comments: {},
  operationSequence: {},
  default: defaultProcessState,
  optimiseQuoteComputedPartProcess: {}
};

export const costBreakdownMap: Record<string, string> = {
  supplies: "suppliesDetail",
  suppliesDetail: "suppliesDetail",
  production: "productionDetail",
  productionDetail: "productionDetail",
  tooling: "toolingDetail",
  toolingDetail: "toolingDetail",
  externalOperation: "externalOperationDetail",
  externalOperationDetail: "externalOperationDetail",
  delivery: "deliveryDetail",
  deliveryDetail: "deliveryDetail"
};

export const costBreakDownChangeInit = {
  productionDetail: false,
  suppliesDetail: false,
  toolingDetail: false,
  externalOperationDetail: false,
  deliveryDetail: false
};

export const initialQuotationState: BriefProviderState = {
  brief: {} as BriefWithRelations,
  processes: {},
  process: 0,
  valueAnalysis: undefined,
  default: defaultState,
  comments: {},
  costBreakdownUpdates: {},
  costBreakdownChanges: costBreakDownChangeInit,
  isUpdatedOnce: false
};

export const clearQuotationState = {
  signature: undefined,
  operationIds: [],
  machineIds: [],
  CostBreakdown: {},
  operationSequence: {},
  valueAnalysis: {},
  predictionRequestComputed: {},
  default: defaultState,
  version: 1,
  isUpdatedOnce: false
};

export const excludedRelationFields = ["machine", "operation", "provider"];

export const optimiseRelationIds = ["machineId", "operationId", "providerId"];

export type OptimiseQuoteSearchFieldsIndex =
  | "machineId"
  | "operationId"
  | "layoutCount"
  | "providerId";

export const optimiseQuoteKeyConverter = {
  machineId: "machine",
  operationId: "operation",
  layoutCount: "layout",
  providerId: "provider"
} as const;

export const optimiseQuoteSearchFields = Object.keys(
  optimiseQuoteKeyConverter
) as Array<keyof typeof optimiseQuoteKeyConverter>;

export const optimiseQuoteComputedFields = [
  "wasteTotal",
  "setupTime",
  "rollingTime",
  "sheetQuantity"
];

// For the operationSequence that are not in the root of the object
export const optimiseQuoteComputedOmitFields = ["sheetQuantity"];

export const optimiseQuoteTimeFields = ["setupTime", "rollingTime"];

export const unitTimeSemiSupervised = "hh:mm";

// I want to be able to
// OptimiseQuoteValueAnalysisPartsEnum["SUPPLY_COST"] and get "suppliesDetail"
// how can i achieve that

export const optimiseQuoteValueAnalysisParts = {
  SUPPLY_COST: "supply-cost.title",
  INTERNAL_PRODUCTION_COST: "production-cost.internal",
  OUTSOURCING_PRODUCTION_COST: "production-cost.external",
  TOOLING_COST: "tooling-cost.title",
  DELIVERY_COST: "delivery-cost.title"
};

export const optimiseQuoteValueAnalysisPartsInitial = {
  SUPPLY_COST: [{ name: "", directCostsSum: null }],
  INTERNAL_PRODUCTION_COST: [{ name: "", directCostsSum: null }],
  OUTSOURCING_PRODUCTION_COST: [{ name: "", directCostsSum: null }],
  TOOLING_COST: [{ name: "", directCostsSum: null }],
  DELIVERY_COST: [{ name: "", directCostsSum: null }]
};

export const OptimiseQuoteValueAnalysisPartsEnum = {
  SUPPLY_COST: "suppliesDetail",
  INTERNAL_PRODUCTION_COST: "productionDetail",
  OUTSOURCING_PRODUCTION_COST: "externalOperationDetail",
  TOOLING_COST: "toolingDetail",
  DELIVERY_COST: "deliveryDetail"
} as const;

export type OptimiseQuoteValueAnalysisPartsEnumType = typeof OptimiseQuoteValueAnalysisPartsEnum[keyof typeof OptimiseQuoteValueAnalysisPartsEnum];

interface CostItem {
  name: keyof typeof OptimiseQuoteValueAnalysisPartsEnum;
  directCostsSum: number | null;
}

interface TransformedValues {
  updatedValues: Partial<
    Record<
      OptimiseQuoteValueAnalysisPartsEnumType,
      { directCostsSum: number | null }
    >
  >;
  meta: any[]; // Remplacez `any` par le type approprié
}
