import { object } from "yup";

export function flattenObjectToPaths(values: any): { [key: string]: any } {
  const result: { [key: string]: any } = {};

  function traverse(currentValue: any, currentPath: string) {
    for (const key in currentValue) {
      const value = currentValue[key];
      const newPath = currentPath ? `${currentPath}.${key}` : key;

      if (Array.isArray(value) && typeof value[0] === "object") {
        // if (Array.isArray(value) && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          traverse(value[i], `${newPath}.${i}`);
        }
      } else if (typeof value === "object" && !Array.isArray(value)) {
        traverse(value, newPath);
      } else {
        result[newPath] = value;
      }
    }
  }

  traverse(values, "");

  return result;
}

export function flattenToObject(path: string, value: any): any {
  const keys = path.split(".");
  const result: any = {};
  let current = result;

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      current[key] = value;
    } else {
      current[key] = {};
      current = current[key];
    }
  });

  return result;
}

type PathValues = { [key: string]: any };

export function transformPathToObject(pathValues: PathValues): PathValues {
  const result: PathValues = {};

  for (const key in pathValues) {
    const keys = key.split(".");
    let current: PathValues = result;

    for (let i = 0; i < keys.length - 1; i++) {
      const k = keys[i];
      if (!current[k]) {
        current[k] = isNaN(Number(keys[i + 1])) ? {} : [];
      }
      current = current[k];
    }

    const lastKey = keys[keys.length - 1];
    if (isNaN(Number(lastKey))) {
      current[lastKey] = pathValues[key];
    } else {
      const index = Number(lastKey);
      if (!current[index]) {
        current[index] = {};
      }
      current[index] = {
        ...current[index],
        ...{ [keys[keys.length - 1]]: pathValues[key] }
      };
    }
  }

  return result;
}

export const transformPathToYupObject = (pathValues: any) => {
  const result = {};

  for (const key in pathValues) {
    const keys = key.split(".");
    let current: any = result;

    for (let i = 0; i < keys.length - 1; i++) {
      const k = keys[i];
      if (!current[k]) {
        current[k] = {};
      }
      current = current[k];
    }

    current[keys[keys.length - 1]] = pathValues[key]; // Assign Yup validation schema
  }

  return object().shape(result); // Return Yup object schema
};
