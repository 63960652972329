import { MixedSchema } from "yup";
import { Image } from "../../entities/image";
import { Submission } from "../../entities/submission";
import { File } from "../../reducers/files/entity";
import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";
import { SubmissionValue } from "../submission/types";
import { Role } from "../../entities/role";

export interface FormParams {
  slug: string;
}

export interface FormIdentifier {
  id?: string;
  slug?: string;
  name?: string;
}

export enum FormName {
  CONFIGURATOR = "configurator",
  COFN = "cofn"
}

export enum FormNodeType {
  SECTION = "section",
  TITLE = "title",
  LINE = "line",
  SEPARATOR = "separator",
  DATE = "date",
  TEXT = "text",
  TEXT_AREA = "textArea",
  NUMBER = "number",
  PERCENTAGE = "percentage",
  CHECKBOX = "checkbox",
  SWITCH = "switch",
  SELECT = "select",
  MULTI_SELECT = "multiSelect",
  SELECT_CUSTOM = "selectCustom",
  MUTLI_SELECT_CUSTOM = "multiSelectCustom",
  RADIO = "radio",
  MULTI_CHECKBOX = "multiCheckbox",
  FILE = "file",
  ARRAY = "array"
}

export enum FormTabsValues {
  GENERAL = "general",
  SECTIONS = "sections",
  SECTIONS_ELEMENTS = "sections_elements",
  ARRAY_ELEMENTS = "array_elements",
  LOGIC = "logic",
  ADVANCED_CONDITIONS = "advanced_conditions",
  VALUES = "values"
}

export const FORM_NODE_LAYOUT = [
  FormNodeType.SECTION,
  FormNodeType.TITLE,
  FormNodeType.LINE,
  FormNodeType.SEPARATOR
];

export const FORM_NODE_INPUTS = [
  FormNodeType.TEXT,
  FormNodeType.TEXT_AREA,
  FormNodeType.NUMBER,
  FormNodeType.PERCENTAGE,
  FormNodeType.CHECKBOX,
  FormNodeType.SWITCH,
  FormNodeType.SELECT,
  FormNodeType.MULTI_SELECT,
  FormNodeType.SELECT_CUSTOM,
  FormNodeType.MUTLI_SELECT_CUSTOM,
  FormNodeType.RADIO,
  FormNodeType.MULTI_CHECKBOX,
  FormNodeType.FILE,
  FormNodeType.ARRAY
];

export enum FORM_NODE_ENUM {
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  NUMBER = "NUMBER",
  PERCENTAGE = "PERCENTAGE",
  CHECKBOX = "CHECKBOX",
  SWITCH = "SWITCH",
  SELECT = "SELECT",
  MULTI_SELECT = "MULTI_SELECT",
  SELECT_CUSTOM = "SELECT_CUSTOM",
  MUTLI_SELECT_CUSTOM = "MUTLI_SELECT_CUSTOM",
  RADIO = "RADIO",
  MULTI_CHECKBOX = "MULTI_CHECKBOX",
  FILE = "FILE"
}

export enum LogicAction {
  VISIBLE = "visible",
  REQUIRE = "require",
  DISABLE = "disable",
  FIXE = "fixe",
  MIN = "min",
  MAX = "max",
  DEFAULT = "default",
  PRECONDITION = "precondition",
  INTERCONDITION = "intercondition"
}

export interface OptionValues {
  id: string;
  value: string;
  label: DynamicJsonTranslator;
  tooltip?: DynamicJsonTranslator;
  image?: File;
  formNodeId?: string | null;
  customFieldNodeId?: string | null;
  createdAt: string;
  position: number;
  fontColor?: string | null;
  backgroundColor?: string | null;
}

export interface Designs {
  label: DynamicJsonTranslator;
  tooltip?: DynamicJsonTranslator;
  helperText?: DynamicJsonTranslator;
  actionLabel?: DynamicJsonTranslator;
  endAdornment?: DynamicJsonTranslator;
  displayOptionImage?: boolean;
  image?: Image;
}

export interface Logics {
  action: LogicAction;
  visible: boolean | null;
  require: boolean | null;
  disable: boolean | null;
  formula: string | boolean | null;
  min: number | null;
  max: number | null;
  fixe: boolean | null;
}

export interface FormNodes {
  id: string;
  position: number;
  type: FormNodeType;
  inputName?: string;
  rootId?: string;
  formId: string;
  parent?: FormNodes;
  root?: Forms;
  form: Forms;
  children?: FormNodes[];
  options?: OptionValues[];
  design?: Designs;
  logics?: Logics[];
  submissionValues?: SubmissionValue[];
  parentId?: string | null;
}

export interface Forms {
  id: string;
  name: string;
  label?: DynamicJsonTranslator;
  description?: DynamicJsonTranslator | null;
  estimatedTime?: number;
  sections: FormNodes[];
  formNodes?: FormNodes[];
  submissions?: Submission[];
  image?: File;
  position: number;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  roles?: Role[];
  // Virtual fields
  roleIds?: string[];
  isAllowingGuest?: boolean;
  /** url identifier */
  slug: string;
  /** draft of the parentId */
  draft: boolean;
  /** standalone branch id */
  rootFormId: string;
  /** XX.YY.ZZZ format */
  version: string;
  /** major breaking change */
  major: boolean;
  /** minor user change */
  minor: boolean;
  parentId?: string;
  parent?: Forms;
  children?: Forms[];
  deletedAt?: string;
}

export type FormVisibilityDTO = {
  id: string;
  roleIds: string[];
  isAllowingGuest: boolean;
};

interface SectionInfo {
  title: DynamicJsonTranslator;
  image: string;
}

export interface FormLogics {
  precondition?: boolean;
  require?: boolean;
  schema?: MixedSchema;
  min?: number;
  max?: number;
  type?: FormNodeType;
  position?: number;
}

export interface FormsTransform extends Forms {
  sectionsInfo: SectionInfo[];
  schema: Record<string, FormLogics>[];
  logics: Record<string, FormLogics>[];
}

export interface FormBreadCrumProps {
  parentNode?: Array<{
    label: DynamicJsonTranslator;
    id: string;
    type: FormNodeType;
  }>;
  form?: { label: FormName; id: string };
}

export interface FormNodeWithRelations {
  noderef: FormNodes;
  breadcrumb: FormBreadCrumProps;
  parentFormNode?: FormNodes;
}
