import i18next from "i18next";
import produce from "immer";

import { NotificationProps } from "../../components/common/Notifier";
import { AppAction, AppActionTypes } from "./action";
import { Currency } from "../../services/currencies/currencies";

export interface AppState {
  theme: "default";
  isMobile: boolean;
  notifications: NotificationProps[];
  language: string;
  currency?: Currency;
  openMenu: boolean;
  isOnBackOffice: boolean;
  redirectAfterSubmit?: string;
}

const initialState: AppState = {
  theme: "default",
  isMobile: false,
  notifications: [],
  currency: undefined,
  language: "",
  openMenu: false,
  isOnBackOffice: false,
  redirectAfterSubmit: undefined
};

export const AppReducer = (state = initialState, action: AppAction): AppState =>
  produce(state, (draft) => {
    switch (action.type) {
      case AppActionTypes.CHANGE_THEME:
        draft.theme = action.theme;
        break;

      case AppActionTypes.ENQUEUE_SNACKBAR:
        // eslint-disable-next-line no-case-declarations
        const notification = {
          message: action.message,
          options: {
            variant: action.variant,
            ...action.options
          },
          key: Math.random().toString(36).substr(2, 9)
        };
        draft.notifications.push(notification);
        break;

      case AppActionTypes.CLOSE_SNACKBAR:
        // eslint-disable-next-line no-case-declarations
        const dismiss = !action.key;
        draft.notifications = state.notifications.map((notification) =>
          dismiss || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        );
        break;

      case AppActionTypes.REMOVE_SNACKBAR:
        draft.notifications = state.notifications.filter(
          (not) => not.key !== action.key
        );
        break;

      case AppActionTypes.SET_LANGUAGE:
        draft.language = action.lang;
        i18next.changeLanguage(action.lang);
        break;
      case AppActionTypes.SET_CURRENCY:
        draft.currency = action.curr;
        break;
      case AppActionTypes.OPEN_MENU:
        draft.openMenu = action.open;
        break;
      case AppActionTypes.IS_ON_BACKOFFICE:
        draft.isOnBackOffice = action.isOnBackOffice;
        break;
      case AppActionTypes.REDIRECT_AFTER_SUBMIT:
        draft.redirectAfterSubmit = action.redirect;
        break;
      default:
        return draft;
    }
  });
