import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Role } from "../../entities/role";
import { ProductSubCategory } from "../../services/subcategory/types";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { MachineRateActions } from "../machineRate/reducer";
import { ProductMachineOperationRate } from "../product-machine-operation-rates/entity";
import { ProductMachineOperationRateActions } from "../product-machine-operation-rates/reducer";
import { ProductProviderOperationRate } from "../product-provider-operation-rates/entity";
import { ProductProviderOperationRateActions } from "../product-provider-operation-rates/reducer";
import { ProviderRateActions } from "../provider-rate/reducer";
import { ProductActionTypes } from "./action";
import * as Api from "./api";
import {
  Product,
  ProductCreateDTO,
  ProductUpdateDTO,
  ProductUpdateOperationsDTO
} from "./entity";
import { CadPattern, CadProduct } from "../../services/cadConfiguration/type";

export interface ProductState extends EntityState<Product> {
  readStatus: FetchingStatus;
  readOneStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  createStatus: FetchingStatus;
  duplicateStatus: FetchingStatus;
  updateOperationsStatus: FetchingStatus;
  addImagesStatus: FetchingStatus;
  setDefaultImageStatus: FetchingStatus;
  removeImageStatus: FetchingStatus;
  createMachineRate: FetchingStatus;
  createMachineRateStatus: FetchingStatus;
  setDefaultMachineRate: FetchingStatus;
  setDefaultMachineRateStatus: FetchingStatus;
  removeMachineRate: FetchingStatus;
  removeMachineRateStatus: FetchingStatus;
  createProviderRate: FetchingStatus;
  createProviderRateStatus: FetchingStatus;
  setDefaultProviderRate: FetchingStatus;
  setDefaultProviderRateStatus: FetchingStatus;
  removeProviderRate: FetchingStatus;
  removeProviderRateStatus: FetchingStatus;
  archivedProductStatus: FetchingStatus;
  archivedProductStatusById: FetchingStatus;
}

export const ProductInitialState: ProductState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  readOneStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  duplicateStatus: FetchingStatus.NULL,
  updateOperationsStatus: FetchingStatus.NULL,
  addImagesStatus: FetchingStatus.NULL,
  setDefaultImageStatus: FetchingStatus.NULL,
  removeImageStatus: FetchingStatus.NULL,
  createMachineRate: FetchingStatus.NULL,
  createMachineRateStatus: FetchingStatus.NULL,
  setDefaultMachineRate: FetchingStatus.NULL,
  setDefaultMachineRateStatus: FetchingStatus.NULL,
  removeMachineRate: FetchingStatus.NULL,
  removeMachineRateStatus: FetchingStatus.NULL,
  createProviderRate: FetchingStatus.NULL,
  createProviderRateStatus: FetchingStatus.NULL,
  setDefaultProviderRate: FetchingStatus.NULL,
  setDefaultProviderRateStatus: FetchingStatus.NULL,
  removeProviderRate: FetchingStatus.NULL,
  removeProviderRateStatus: FetchingStatus.NULL,
  archivedProductStatus: FetchingStatus.NULL,
  archivedProductStatusById: FetchingStatus.NULL
};

export const ProductAdapter = createEntityAdapter<Product>();

const ProductAdapterState = ProductAdapter.getInitialState(ProductInitialState);

export const read = createMyAsyncThunk<Product[], boolean | undefined>(
  ProductActionTypes.READ,
  Api.read
);

export const readOne = createMyAsyncThunk<
  Product,
  { id: string; queryParams?: string }
>(ProductActionTypes.READ_ONE, Api.readOne);

export const update = createMyAsyncThunk<Product, ProductUpdateDTO>(
  ProductActionTypes.UPDATE,
  Api.update,
  { onSuccessMessage: "saga:update-success" }
);

export const create = createMyAsyncThunk<Product, ProductCreateDTO>(
  ProductActionTypes.CREATE,
  Api.create,
  { onSuccessMessage: "saga:create-success" }
);

export const duplicate = createMyAsyncThunk<Product, Api.ProductDuplicateProps>(
  ProductActionTypes.DUPLICATE,
  Api.duplicate,
  { onSuccessMessage: "saga:create-success" }
);

/* Operations */

export const updateOperations = createMyAsyncThunk<
  Product,
  ProductUpdateOperationsDTO
>(ProductActionTypes.UPDATE_OPERATIONS, Api.updateOperations, {
  onSuccessMessage: "saga:update-success"
});

/* Images */
export const addImages = createMyAsyncThunk<Product, Api.ProductAddImagesProps>(
  ProductActionTypes.ADD_IMAGES,
  Api.addImages,
  { onSuccessMessage: "saga:products:addImages" }
);

export const setDefaultImage = createMyAsyncThunk<
  Product,
  Api.ProductSetDefaultImageProps
>(ProductActionTypes.SET_DEFAULT_IMAGE, Api.setDefaultImage, {
  onSuccessMessage: "saga:update-success"
});

export const removeImage = createMyAsyncThunk<
  Product,
  Api.ProductRemoveImageProps
>(ProductActionTypes.REMOVE_IMAGE, Api.removeImage, {
  onSuccessMessage: "saga:delete-success"
});

/* Machine rates */

export const createMachineRate = createMyAsyncThunk<
  ProductMachineOperationRate,
  Api.ProductCreateMachineRateProps
>(ProductActionTypes.CREATE_MACHINE_RATE, Api.createMachineRate, {
  onSuccessMessage: "saga:create-success",
  onSuccess: ({ result, values, thunkApi }) => {
    thunkApi.dispatch(
      MachineRateActions.upsertOne({
        ...values.machineRate,
        id: result.machineRateId
      })
    );
    thunkApi.dispatch(
      ProductMachineOperationRateActions.upsertOne({
        id: `${values.productId}_${values.machineOperationRateId}`,
        ...result
      })
    );
  }
});

export const setDefaultMachineRate = createMyAsyncThunk<
  ProductMachineOperationRate,
  Api.ProductSetDefaultMachineRateProps
>(ProductActionTypes.SET_DEFAULT_MACHINE_RATE, Api.setDefaultMachineRate, {
  onSuccessMessage: "saga:update-success",
  onSuccess: ({ result, values, thunkApi }) => {
    thunkApi.dispatch(
      ProductMachineOperationRateActions.upsertOne({
        id: `${values.productId}_${values.machineOperationRateId}`,
        ...result
      })
    );
  }
});

export const removeMachineRate = createMyAsyncThunk<
  Product,
  Api.ProductRemoveMachineRateProps
>(ProductActionTypes.REMOVE_MACHINE_RATE, Api.removeMachineRate, {
  onSuccessMessage: "saga:delete-success",
  onSuccess: ({ values, thunkApi }) => {
    if (!values.isDefault) {
      thunkApi.dispatch(MachineRateActions.removeOne(values.machineRateId));
    }
    thunkApi.dispatch(
      ProductMachineOperationRateActions.removeOne(
        `${values.productId}_${values.machineOperationRateId}`
      )
    );
  }
});

/* Provider rates */

export const createProviderRate = createMyAsyncThunk<
  ProductProviderOperationRate,
  Api.ProductCreateProviderRateProps
>(ProductActionTypes.CREATE_PROVIDER_RATE, Api.createProviderRate, {
  onSuccessMessage: "saga:create-success",
  onSuccess: ({ result, values, thunkApi }) => {
    thunkApi.dispatch(
      ProviderRateActions.upsertOne({
        ...values.providerRate,
        id: result.providerRateId
      })
    );
    thunkApi.dispatch(
      ProductProviderOperationRateActions.upsertOne({
        id: `${values.productId}_${values.providerOperationRateId}`,
        ...result
      })
    );
  }
});

export const setDefaultProviderRate = createMyAsyncThunk<
  ProductProviderOperationRate,
  Api.ProductSetDefaultProviderRateProps
>(ProductActionTypes.SET_DEFAULT_PROVIDER_RATE, Api.setDefaultProviderRate, {
  onSuccessMessage: "saga:update-success",
  onSuccess: ({ result, values, thunkApi }) => {
    thunkApi.dispatch(
      ProductProviderOperationRateActions.upsertOne({
        id: `${values.productId}_${values.providerOperationRateId}`,
        ...result
      })
    );
  }
});

export const removeProviderRate = createMyAsyncThunk<
  Product,
  Api.ProductRemoveProviderRateProps
>(ProductActionTypes.REMOVE_PROVIDER_RATE, Api.removeProviderRate, {
  onSuccessMessage: "saga:delete-success",
  onSuccess: ({ values, thunkApi }) => {
    if (!values.isDefault) {
      thunkApi.dispatch(ProviderRateActions.removeOne(values.providerRateId));
    }
    thunkApi.dispatch(
      ProductProviderOperationRateActions.removeOne(
        `${values.productId}_${values.providerOperationRateId}`
      )
    );
  }
});

/* Archived */
export const archiveProduct = createMyAsyncThunk<
  Product,
  Api.archiveProductProps
>(ProductActionTypes.ARCHIVE_PRODUCT, Api.archiveProduct, {
  onSuccessMessage: "saga:delete-success"
});

export const getArchivedById = createMyAsyncThunk<
  Product,
  Api.archiveProductProps
>(ProductActionTypes.GET_ARCHIVE_PRODUCT, Api.getArchivedById);

const ProductSlice = createMySlice({
  name: "products",
  initialState: ProductAdapterState,
  adapter: ProductAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ProductAdapter.upsertMany
    },
    {
      action: readOne,
      statusName: "readOneStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: update,
      statusName: "updateStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: duplicate,
      statusName: "duplicateStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: updateOperations,
      statusName: "updateOperationsStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: addImages,
      statusName: "addImagesStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: removeImage,
      statusName: "removeImageStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: setDefaultImage,
      statusName: "setDefaultImageStatus",
      onSuccess: ProductAdapter.upsertOne
    },
    {
      action: createMachineRate,
      statusName: "createMachineRateStatus"
    },
    {
      action: setDefaultMachineRate,
      statusName: "setDefaultMachineRateStatus"
    },
    {
      action: removeMachineRate,
      statusName: "removeMachineRateStatus"
    },
    {
      action: createProviderRate,
      statusName: "createProviderRateStatus"
    },
    {
      action: setDefaultProviderRate,
      statusName: "setDefaultProviderRateStatus"
    },
    {
      action: removeProviderRate,
      statusName: "removeProviderRateStatus"
    },
    {
      action: archiveProduct,
      statusName: "archivedProductStatus",
      onSuccess: (state, action) => {
        state.ids = state.ids.filter((id) => id !== action.meta.arg.productId);
        delete state.entities[action.meta.arg.productId]; // mutating object
      }
    },
    {
      action: getArchivedById,
      statusName: "archivedProductStatusById"
    }
  ],
  reducers: {
    updateProductSubCategory(
      state: any,
      action: { payload: Product } | undefined
    ) {
      const prevState = ProductAdapter.getSelectors().selectById(
        state,
        action?.payload.id as string
      );

      if (prevState) {
        ProductAdapter.setOne(state, {
          ...prevState,
          subCategoriesIds: action?.payload.subCategoriesIds as string[],
          subCategories: action?.payload.subCategories as ProductSubCategory[]
        });
      }
    },
    updateProductPattern(state: any, action: { payload: Product } | undefined) {
      const prevState = ProductAdapter.getSelectors().selectById(
        state,
        action?.payload.id as string
      );

      if (prevState) {
        ProductAdapter.setOne(state, {
          ...prevState,
          productLayoutPatterns: action?.payload
            .productLayoutPatterns as CadPattern[]
        });
      }
    },
    updateProductVisibility(
      state: any,
      action: { payload: Product } | undefined
    ): any {
      const prevState = ProductAdapter.getSelectors().selectById(
        state,
        action?.payload.id as string
      );

      if (prevState) {
        ProductAdapter.setOne(state, {
          ...prevState,
          roleIds: action?.payload.roleIds as string[],
          roles: action?.payload.roles as Role[],
          isAllowingGuest: action?.payload.isAllowingGuest
        });
      }
    }
  }
});

export const ProductReducer = ProductSlice.reducer;
export const {
  updateProductSubCategory,
  updateProductVisibility,
  updateProductPattern
} = ProductSlice.actions;
export const ProductActions = {
  ...ProductSlice.actions,
  async: {
    read,
    readOne,
    update,
    create,
    duplicate,
    updateOperations,
    addImages,
    removeImage,
    setDefaultImage,
    createMachineRate,
    setDefaultMachineRate,
    removeMachineRate,
    createProviderRate,
    setDefaultProviderRate,
    removeProviderRate,
    archiveProduct,
    getArchivedById
  }
};
