import { filter } from "lodash";
import { AxiosError } from "axios";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { TruckFormat } from "./type";
import { queryEntityOptions } from "../common/utils";
import { AutoCompleteOption } from "../../components/common/form/MyAutocompleteField";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["TruckFormat"]
});

const extendedApiSlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getTruckFormat: build.query<TruckFormat[], void>({
      query: () => "/truck-formats",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "TruckFormat" as const,
                id
              })),
              { type: "TruckFormat", id: "PARTIAL-LIST" }
            ]
          : [{ type: "TruckFormat", id: "PARTIAL-LIST" }]
    }),
    getTruckFormOption: build.query<AutoCompleteOption[], void>({
      ...queryEntityOptions({
        url: "/truck-formats",
        type: "TruckFormat",
        transform: (trucks: TruckFormat[]) =>
          trucks
            .filter((truck) => truck.enabled)
            .map((truck) => ({ value: truck.id, label: truck.name })),
        fields: ["name"]
      })
    }),
    createTruckFormat: build.mutation<TruckFormat, Partial<TruckFormat>>({
      query(truck) {
        return {
          url: "/truck-formats",
          method: "POST",
          body: truck
        };
      },
      invalidatesTags: ["TruckFormat"],
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:create-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed")
            )
          );
        }
      }
    }),
    updateTruckFormat: build.mutation<TruckFormat, Partial<TruckFormat>>({
      query(truck) {
        const { id, ...patch } = truck;
        return {
          url: `/truck-formats/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      invalidatesTags: ["TruckFormat"],
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:create-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed")
            )
          );
        }
      }
    })
  })
});

export const {
  useGetTruckFormatQuery,
  useGetTruckFormOptionQuery,
  useCreateTruckFormatMutation,
  useUpdateTruckFormatMutation
} = extendedApiSlice;
