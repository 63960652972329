import { ExchangeRate } from "./../../services/currencies/currencies";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getCurrency, getLanguage } from "../../reducers/app/selector";
import {
  getCompanyCurrency,
  getCurrencyExchangeRate,
  getDefaultCurrency,
  getDefaultExchangeRate,
  getUserCurrency
} from "../../reducers/appSettings/selector";
import { RootState } from "../../reducers";
import { Company } from "../../entities/company";
import { Currency } from "../../services/currencies/currencies";
import { CurrencyContext } from "../../services/currencies/currencies.type";
import { store } from "../../reducers/store";

const formatWithPrefixSuffix = (
  numberFormat: string,
  prefix?: string,
  suffix?: string
) => {
  if (prefix && suffix) {
    return `${prefix}${numberFormat}${suffix}`;
  } else if (suffix) {
    return `${numberFormat}${suffix}`;
  } else if (prefix) {
    return `${prefix}${numberFormat}`;
  } else {
    return numberFormat;
  }
};

const formatCurrency = (
  lang: string,
  currency: Currency,
  value: number,
  onlySymbol: boolean,
  applyRate: boolean,
  exchangeRate?: ExchangeRate,
  options?: Intl.NumberFormatOptions & { signDisplay: string } //quickfix a bug of ts 3.8
): string => {
  let updatedValue = value;

  if (onlySymbol) {
    return currency?.symbol;
  }

  if (applyRate) {
    if (currency?.code !== exchangeRate?.baseCode) {
      const rate = exchangeRate?.conversionRates?.[currency.code];
      if (rate) {
        updatedValue = value * rate;
      }
    }
  }

  const numberFormat = new Intl.NumberFormat(lang, {
    style: "currency",
    currency: currency?.code,
    currencyDisplay: "narrowSymbol",
    ...options
  });

  return numberFormat.format(updatedValue);
};

export interface UseCurrencyDisplayParams {
  value?: string | number;
  suffix?: string;
  prefix?: string;
  replaceEmptyValues?: string;
  company?: Company;
  onlySymbol?: boolean;
  context?: CurrencyContext;
  exchangeRate?: ExchangeRate;
  applyRate?: boolean;
  enabled?: boolean;
  formatOptions?: Intl.NumberFormatOptions & { signDisplay: string };
}

interface UseCurrencyDisplayReturn {
  formattedCurrency: string | undefined;
  format: ({
    value,
    suffix,
    prefix,
    company,
    onlySymbol,
    context,
    exchangeRate
  }: UseCurrencyDisplayParams) => string | undefined;
}

const useCurrencyDisplay = ({
  value,
  suffix,
  prefix,
  company,
  onlySymbol = false,
  enabled = true,
  applyRate = false,
  context = CurrencyContext.SESSION
}: UseCurrencyDisplayParams = {}): UseCurrencyDisplayReturn => {
  const lang = useSelector(getLanguage);

  const {
    sessionCurrency,
    userCurrency,
    // companyCurrency,
    defaultCurrency
  } = useSelector((state: RootState) => ({
    sessionCurrency: getCurrency(state),
    userCurrency: getUserCurrency(state),
    //companyCurrency: getCompanyCurrency(company)(state),
    defaultCurrency: getDefaultCurrency(state)
  }));

  const exchangeRateDefault = useSelector(getDefaultExchangeRate);
  const exchangeRateCurrencies = useSelector(getCurrencyExchangeRate);

  const format = useCallback(
    ({
      value,
      suffix,
      prefix,
      company,
      replaceEmptyValues: fallBack,
      onlySymbol = false,
      formatOptions,
      applyRate = false,
      // Override the default exchange rate eg. for the brief
      exchangeRate,
      context = CurrencyContext.SESSION
    }: UseCurrencyDisplayParams) => {
      // Usefull to not display anything & computation
      if (!enabled) return "";

      // Usefull for tables
      if (fallBack && !value) {
        return fallBack;
      }

      // Usefull for numeric input with endAdornment
      if (!value && !onlySymbol) {
        return value as string;
      }

      const numericValue = typeof value === "number" ? value : Number(value);

      let currency: Currency | undefined;
      switch (context) {
        case CurrencyContext.USER:
          currency = userCurrency;
          break;
        case CurrencyContext.COMPANY:
          currency = getCompanyCurrency(company)(store.getState());
          break;
        case CurrencyContext.SESSION:
          currency = sessionCurrency;
          break;
        case CurrencyContext.BASE:
        default:
          currency = defaultCurrency;
          break;
      }

      const numberFormat = formatCurrency(
        lang,
        currency!,
        numericValue,
        onlySymbol,
        applyRate,
        exchangeRate ?? exchangeRateDefault,
        formatOptions
      );
      return formatWithPrefixSuffix(numberFormat, prefix, suffix);
    },
    [
      defaultCurrency,
      enabled,
      exchangeRateDefault,
      lang,
      sessionCurrency,
      userCurrency
    ]
  );

  const formattedCurrency = useMemo(() => {
    if (!enabled) return "";
    return format({
      value: Number(value),
      suffix,
      prefix,
      company,
      onlySymbol,
      context,
      applyRate
    });
  }, [
    applyRate,
    company,
    context,
    enabled,
    format,
    onlySymbol,
    prefix,
    suffix,
    value
  ]);

  return {
    formattedCurrency,
    format
  };
};

export default useCurrencyDisplay;
