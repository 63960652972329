export enum CurrencyContext {
  "BASE" = "base",
  "USER" = "user",
  "SESSION" = "session",
  "COMPANY" = "company"
}

export interface CurrencyTable {
  hasCurrency?: boolean;
  exchangeRatePath?: string;
}
