type AnyObject = { [key: string]: any };

/**
 * Update an object based on dot-separated keys without overriding existing nested keys.
 *
 * @param base - The base object to be updated.
 * @param updates - An object with dot-separated keys and values to update.
 */
export function conciliateObjects(
  base: AnyObject,
  updates: AnyObject
): AnyObject {
  for (const keyPath in updates) {
    const value = updates[keyPath];
    const keys = keyPath.split(".");

    let current = base;
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];

      // Check if the current key exists and is an object; if not, create it
      if (typeof current[key] !== "object" || current[key] === null) {
        current[key] = {};
      }
      current = current[key];
    }

    const lastKey = keys[keys.length - 1];

    // If the last key already exists as an object, we merge deeply
    if (typeof current[lastKey] === "object" && typeof value === "object") {
      // Usefull to merge deeply even  and avoir the read ts error
      const updatedValue = conciliateObjects({ ...current[lastKey] }, value);
      //const descriptor = Object.getOwnPropertyDescriptor(current, lastKey);
      //console.log("descriptor", descriptor, lastKey);
      current[lastKey] = updatedValue;
    } else {
      // Otherwise, directly assign the value
      current[lastKey] = value;
    }
  }

  return base;
}

export const deepMergeOrdered = (target: any, source: any): any => {
  const result = { ...target };

  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      result[key] = deepMergeOrdered(target[key], source[key]);
    } else {
      result[key] = source[key];
    }
  }

  return result;
};

// Function that merges two objects deeply

export const deepMerge = (target: any, source: any): any => {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  return { ...target, ...source };
};

export function getLastValue(obj: Record<string, any>): any {
  let currentValue = obj;

  while (typeof currentValue === "object" && currentValue !== null) {
    const keys = Object.keys(currentValue);
    if (keys.length === 0) break; // Si l'objet est vide, sortir de la boucle
    currentValue = currentValue[keys[keys.length - 1]];
  }

  return currentValue;
}
