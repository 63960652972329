import { createSelector } from "reselect";

import { RootState } from "../";
import { getConfigCurrency } from "../appSettings/selector";

export const getAppState = createSelector(
  (state: RootState) => state.app,
  (app) => app
);

export const getLanguage = createSelector(
  (state: RootState) => state.app,
  (app) => app.language
);

export const getCurrency = createSelector(
  (state: RootState) => state.app,
  getConfigCurrency,
  (app, currency) => app.currency ?? currency
);

export const getOpenMenu = createSelector(getAppState, (app) => app.openMenu);
